import { useCallback, useContext, useState } from 'react';
import { ethers, Contract } from 'ethers';

import { useProvider, useWeb3 } from 'hooks';
import { StakingContract } from 'ethereum/contracts';
import { SnackbarContext } from 'components/Snackbar';

export const UNSTAKE_STATUS = {
  INIT: { step: 0, status: 'init' },
  CONFIRMATION_NEEDED: { step: 0, status: 'confirmation_needed' },
  SUCCESS: { step: 1, status: 'success' },
  CANCELED: {
    step: 1,
    status: 'canceled',
  },
  ERROR: { step: 1, status: 'error' },
};

export function useUnstake() {
  // Hooks
  const { account } = useWeb3();
  const provider = useProvider();

  // Context
  const { openSnackbar } = useContext(SnackbarContext);

  // State
  const [error, setError] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);

  const unstake = useCallback(
    async (amount) => {
      try {
        if (!account) {
          throw new Error('No account connected');
        }

        setLoading(true);
        setCanceled(false);
        setError(undefined);
        setStatus(UNSTAKE_STATUS.INIT);

        setStatus(UNSTAKE_STATUS.CONFIRMATION_NEEDED);
        const contract = new Contract(
          StakingContract.address,
          StakingContract.abi,
          provider.getSigner(),
        );

        const claimTx = await contract.unstake(ethers.utils.parseEther(amount));
        await claimTx.wait();

        openSnackbar(
          'success',
          'Transaction Confirmed',
          'You can close transaction process.',
        );
        setStatus(UNSTAKE_STATUS.SUCCESS);
      } catch (error: any) {
        if (error.code === 4001) {
          openSnackbar(
            'warning',
            'Transaction Canceled',
            'You can close transaction process.',
          );
          setCanceled(error);
          setStatus(UNSTAKE_STATUS.CANCELED);
        } else {
          openSnackbar(
            'error',
            'Transaction Error',
            'Something went wrong. You can close transaction process.',
          );
          setError(error);
          setStatus(UNSTAKE_STATUS.ERROR);
        }
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, provider],
  );

  return {
    error,
    status,
    loading,
    canceled,
    unstake,
  };
}
