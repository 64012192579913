import React from 'react';
import { CircularProgress } from '@mui/material';

type Props = {
  size: number;
};

export const Loader = ({ size }: Props) => {
  return <CircularProgress size={size} color="secondary" />;
};
