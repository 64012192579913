import { styled } from '@mui/material/styles';
import { Box, Stepper, Step, StepLabel, StepContent } from '@mui/material';

export const StakingWrapper = styled('div')`
  display: block;
  width: 210px;

  @media screen and (min-width: 360px) {
    width: 250px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    width: 475px;
  }
`;

export const ClaimWrapper = styled('div')`
  display: block;
  width: 210px;

  @media screen and (min-width: 360px) {
    width: 250px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    width: 475px;
  }
`;

export const StyledStepper = styled(Stepper)`
  && {
    margin: 28px 0 20px;
  }
`;

export const StyledStepperStep = styled(Step)`
  && {
  }
`;

export const StyledStepLabel = styled(StepLabel)`
  && {
    .MuiStepLabel-label {
      color: #9e9e9e;
      font-size: 12px;
      font-weight: 600;

      &.Mui-active {
        color: #20d1f1;
      }
    }

    .MuiSvgIcon-root {
      width: 27px;
      height: 27px;
      color: transparent;
      border-radius: 14px;
      border: 2px solid #9e9e9e;

      .MuiStepIcon-text {
        fill: #9e9e9e;
        font-size: 12px;
        font-weight: 600;
      }

      &.Mui-active {
        fill: transparent;
        border: 2px solid #20d1f1;

        .MuiStepIcon-text {
          fill: #fff;
        }
      }
    }
  }
`;

export const StyledStepContent = styled(StepContent)`
  && {
    padding-left: 24px;
  }
`;

export const StepDescription = styled('p')`
  display: block;
  margin: 0 0 14px;
  padding: 0;
  color: #bdbdbd;
  font-size: 12px;
  letter-spacing: 0.2px;
`;

export const StatusBox = styled(Box)`
  && {
    display: flex;
    margin: 4px 0;
    padding: 0;
    align-items: center;
    justify-content: flex-start;
  }
`;
