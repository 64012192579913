import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

//
type Props = {
  active?: boolean;
} & SvgIconProps;

const SvgDashboardIco: React.FC = ({ active }: Props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{
        fill: 'none',
        stroke: active ? '#00caef' : '#e0e0e0',
        strokeWidth: 1.4,
        strokeMiterlimit: 10,
      }}
      d="M14,11c0,1.7-1.3,3-3,3c-1.7,0-3-1.3-3-3c0-1.7,1.3-3,3-3C12.7,8,14,9.3,14,11z M11,2v6V2z M13.1,13.1l4.2,4.2 L13.1,13.1z M20,11c0,5-4,9-9,9s-9-4-9-9c0-5,4-9,9-9S20,6,20,11z"
    />
  </SvgIcon>
);

export { SvgDashboardIco };
