import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled(Tabs)`
  && {
  }
`;

export const StyledTab = styled(Tab)`
  && {
    margin: 0 16px 0 0;
    border-radius: 3px;
    text-transform: none;

    box-shadow: 0 2px 0 -1px rgba(18, 18, 18, 0.35), inset 0 -1px 0 0 #383838,
      inset 0 1px 0 0 #2c2c2c;
    border: solid 1px #383838;
    background-color: #2e2e2e;

    &.Mui-selected {
      border: solid 1px #6156ae;
      background-color: #34313b;
      box-shadow: 0 2px 0 -1px rgba(18, 18, 18, 0.35), inset 0 -1px 0 0 #6156ae,
        inset 0 1px 0 0 rgba(129, 114, 232, 0.1);
    }
  }
`;
