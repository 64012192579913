import React from 'react';

import {
  Wrapper,
  Content,
  StyledLinearProgress as MuiLinearProgress,
} from './Linear.styles';

//
type Props = {
  value: number | string;
};

export const LinearProgress = ({ value }: Props) => {
  return (
    <Wrapper>
      <Content>
        <MuiLinearProgress
          color="secondary"
          variant="determinate"
          value={Number(value)}
        />
      </Content>
    </Wrapper>
  );
};
