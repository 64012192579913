import { styled } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';

export const StyledOutlinedInput = styled(OutlinedInput)`
  && {
    width: 100%;

    &.MuiInputBase-root {
      color: #bdbdbd;
      padding-right: 6px;
    }

    .MuiOutlinedInput-input {
      margin: 0;
      padding: 14px;

      &::placeholder {
        color: #bdbdbd;
      }
    }

    &.MuiOutlinedInput-root {
      border-radius: 3px;
      border: solid 1px #3c3c3c;
      background-color: #2c2c2c;
      box-shadow: inset 0 1px 0 2px #353535, inset 0 1px 1px 1px #333,
        inset 0 -1px 0 1px #2c2c2c, inset 0 0 0 1px #2c2c2c;

      &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: solid 1px #9184eb;
      }

      &.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border: solid 1px #f2304d;
      }

      .MuiButton-root {
        top: 2px;
        font-size: 12px;
        max-height: 35px;
        position: relative;
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #3c3c3c;
        }
      }
    }
  }
`;
