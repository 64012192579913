import React, { useState } from 'react';

import { Tooltip } from 'components/Tooltip';
import {
  Wrapper,
  IndexIcon,
  SubmenuList,
  StakeMenuIcon,
  StudioMenuIcon,
  SubmenuListItem,
  EearlyStageIcon,
  ItemListContent,
  StakingProgramIco,
  PortfolioMenuIcon,
  DashboardMenuIcon,
  LiquidityProgramIcon,
  StyledLink as Link,
  StyledList as List,
  StyledSubLink as SubLink,
  StyledListItem as ListItem,
  StyledCollapse as Collapse,
  StyledArrowDropdownIcon as ArrowDropdownIcon,
} from './Menu.styles';

//
export const Menu = () => {
  // State
  const [openIndexSubmenuList, setOpenIndexSubmenuList] = useState(false);
  const [openStudioSubmenuList, setOpenStudioSubmenuList] = useState(false);
  const [openEarlyStageSubmenuList, setOpenEarlyStageSubmenuList] =
    useState(false);
  const [openLiquidityProgramSubmenuList, setOpenLiquidityProgramSubmenuList] =
    useState(false);
  const [openStakingProgramSubmenuList, setOpenStakingProgramSubmenuList] =
    useState(false);

  // Handlers
  const handleIndexCollapseSubmenuList = () => {
    setOpenStudioSubmenuList(false);
    setOpenEarlyStageSubmenuList(false);
    setOpenStakingProgramSubmenuList(false);
    setOpenLiquidityProgramSubmenuList(false);
    setOpenIndexSubmenuList(!openIndexSubmenuList);
  };

  const handleStudioCollapseSubmenuList = () => {
    setOpenIndexSubmenuList(false);
    setOpenEarlyStageSubmenuList(false);
    setOpenStakingProgramSubmenuList(false);
    setOpenLiquidityProgramSubmenuList(false);
    setOpenStudioSubmenuList(!openStudioSubmenuList);
  };

  const handleEarlyStageCollapseSubmenuList = () => {
    setOpenIndexSubmenuList(false);
    setOpenStudioSubmenuList(false);
    setOpenStakingProgramSubmenuList(false);
    setOpenLiquidityProgramSubmenuList(false);
    setOpenEarlyStageSubmenuList(!openEarlyStageSubmenuList);
  };

  const handleLiquidityProgramSubmenuList = () => {
    setOpenIndexSubmenuList(false);
    setOpenStudioSubmenuList(false);
    setOpenEarlyStageSubmenuList(false);
    setOpenStakingProgramSubmenuList(false);
    setOpenLiquidityProgramSubmenuList(!openLiquidityProgramSubmenuList);
  };

  const handleStakingProgramSubmenuList = () => {
    setOpenIndexSubmenuList(false);
    setOpenStudioSubmenuList(false);
    setOpenEarlyStageSubmenuList(false);
    setOpenLiquidityProgramSubmenuList(false);
    setOpenStakingProgramSubmenuList(!openStakingProgramSubmenuList);
  };

  return (
    <Wrapper>
      <List>
        <Tooltip title="Coming in 2022" placement="right">
          <span>
            <ListItem disabled>
              <Link to="/dashboard">
                <ItemListContent>
                  <DashboardMenuIcon />
                  <div>
                    <span>Dashboard</span>
                  </div>
                </ItemListContent>
              </Link>
            </ListItem>
          </span>
        </Tooltip>

        <ListItem>
          <Link to="/stake">
            <ItemListContent>
              <StakeMenuIcon active />
              <div>
                <span>Stake</span>
              </div>
            </ItemListContent>
          </Link>
        </ListItem>

        <Tooltip title="Coming in 2022" placement="right">
          <span>
            <ListItem disabled>
              <Link to="/portfolio">
                <ItemListContent>
                  <PortfolioMenuIcon />
                  <div>
                    <span>Portfolio</span>
                  </div>
                </ItemListContent>
              </Link>
            </ListItem>
          </span>
        </Tooltip>

        <ListItem
          active={openStudioSubmenuList ? 1 : 0}
          onClick={handleStudioCollapseSubmenuList}
        >
          <ItemListContent>
            <StudioMenuIcon active={openStudioSubmenuList ? 1 : 0} />
            <div>
              <span>Studio</span>
              <ArrowDropdownIcon active={openStudioSubmenuList ? 1 : 0} />
            </div>
          </ItemListContent>
        </ListItem>

        <Collapse in={openStudioSubmenuList} timeout="auto" unmountOnExit>
          <SubmenuList>
            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/studio/talent">
                    <span>Talent</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>

            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/studio/hiring">
                    <span>Hiring</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>
          </SubmenuList>
        </Collapse>

        <ListItem
          active={openEarlyStageSubmenuList ? 1 : 0}
          onClick={handleEarlyStageCollapseSubmenuList}
        >
          <ItemListContent>
            <EearlyStageIcon active={openEarlyStageSubmenuList ? 1 : 0} />
            <div>
              <span>Early stage</span>
              <ArrowDropdownIcon active={openEarlyStageSubmenuList ? 1 : 0} />
            </div>
          </ItemListContent>
        </ListItem>

        <Collapse in={openEarlyStageSubmenuList} timeout="auto" unmountOnExit>
          <SubmenuList>
            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/early-stage/deal-flow">
                    <span>Deal flow</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>

            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/early-stage/analysis">
                    <span>Analysis</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>

            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/early-stage/investment-committee">
                    <span>Investment committee</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>

            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/early-stage/portfolio">
                    <span>Colony's portfolio</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>
          </SubmenuList>
        </Collapse>

        <ListItem
          active={openLiquidityProgramSubmenuList ? 1 : 0}
          onClick={handleLiquidityProgramSubmenuList}
        >
          <ItemListContent>
            <LiquidityProgramIcon
              active={openLiquidityProgramSubmenuList ? 1 : 0}
            />
            <div>
              <span>Liquidity program</span>
              <ArrowDropdownIcon
                active={openLiquidityProgramSubmenuList ? 1 : 0}
              />
            </div>
          </ItemListContent>
        </ListItem>

        <Collapse
          unmountOnExit
          timeout="auto"
          in={openLiquidityProgramSubmenuList}
        >
          <SubmenuList>
            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/liquidity-program/pools-deployed">
                    <span>Pools deployed</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>

            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/liquidity-program/new-pool">
                    <span>Submit new pool</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>
          </SubmenuList>
        </Collapse>

        <ListItem
          active={openStakingProgramSubmenuList ? 1 : 0}
          onClick={handleStakingProgramSubmenuList}
        >
          <ItemListContent>
            <StakingProgramIco active={openStakingProgramSubmenuList ? 1 : 0} />
            <div>
              <span>Staking program</span>
              <ArrowDropdownIcon
                active={openStakingProgramSubmenuList ? 1 : 0}
              />
            </div>
          </ItemListContent>
        </ListItem>

        <Collapse
          unmountOnExit
          timeout="auto"
          in={openStakingProgramSubmenuList}
        >
          <SubmenuList>
            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/staking-program/avax">
                    <span>AVAX</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>

            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/staking-program/subnet">
                    <span>Subnets</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>
          </SubmenuList>
        </Collapse>

        <ListItem
          active={openIndexSubmenuList ? 1 : 0}
          onClick={handleIndexCollapseSubmenuList}
        >
          <ItemListContent>
            <IndexIcon active={openIndexSubmenuList ? 1 : 0} />
            <div>
              <span>Index</span>
              <ArrowDropdownIcon active={openIndexSubmenuList ? 1 : 0} />
            </div>
          </ItemListContent>
        </ListItem>

        <Collapse in={openIndexSubmenuList} timeout="auto" unmountOnExit>
          <SubmenuList>
            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/index/constituants">
                    <span>Index constituants</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>

            <Tooltip title="Coming in 2022" placement="right">
              <span>
                <SubmenuListItem disabled>
                  <SubLink to="/index/vote">
                    <span>Vote on Index rules</span>
                  </SubLink>
                </SubmenuListItem>
              </span>
            </Tooltip>
          </SubmenuList>
        </Collapse>
      </List>
    </Wrapper>
  );
};
