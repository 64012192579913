import { styled } from '@mui/material/styles';
import { SwipeableDrawer } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

//
export const Wrapper = styled('div')`
  display: flex;
  height: 100%;
  margin: 0 20px 0 0;
  padding: 0;
  flex-direction: column;
`;

export const Left = styled('div')`
  display: flex;
`;

export const Right = styled('div')`
  display: flex;
`;

export const Header = styled('div')`
  display: flex;
  margin: 0;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #1e1e1e;
`;

export const Content = styled('div')`
  display: flex;
  height: 100%;
  margin: 10px 0 20px 20px;
  padding: 0;
  flex-direction: column;
  justify-content: space-between;
`;

export const AppLogo = styled('div')`
  display: flex;
  margin: 0;
  padding: 24px 0 24px 10px;

  > img {
    display: block;
    margin: 0 14px;
    z-index: 20;
    width: 120px;
    height: auto;
  }
`;

export const StyledSwipeableDrawer = styled(SwipeableDrawer)`
  && {
    > .MuiPaper-root {
      background-color: #272727;
    }
  }
`;

export const StyledMenuIcon = styled(MenuIcon)`
  && {
    width: 2rem;
    height: auto;
    color: #40d7f3;
  }
`;
