import { styled } from '@mui/material/styles';
import { ArrowForwardIos } from '@mui/icons-material/';

export const Wrapper = styled('div')`
  display: flex;
  margin: 0 16px;
  padding: 8px 10px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #2e2e2e;
  border: solid 1px #333;
  box-shadow: 0 3px 0 -1px rgba(18, 18, 18, 0.35), inset 0 -3px 0 0 #616161,
    inset 0 -1px 0 1px #2c2c2c;

  > span {
    margin: 0 2px 0 10px;
    padding: 0;
    color: #e0e0e0;
    font-weight: 600;
  }

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }
`;

export const IconContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 22px;
    height: auto;
  }
`;

export const Label = styled('div')`
  display: none;
  align-items: center;
  justify-content: center;

  span {
    color: #a195ee;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.25px;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    display: flex;
  }
`;

export const ArrowIcon = styled(ArrowForwardIos)`
  && {
    display: block;
    margin: 0 2px 0 4px;
    font-size: 10px;
    color: #a195ee;
    position: relative;
    top: 1px;
  }
`;
