import { Close, WarningAmber } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Container, Grid, IconButton } from '@mui/material';

type WrapperProps = {
  open: boolean | number;
};

//
export const StyledGrid = styled(Grid)``;

export const StyledContainer = styled(Container)`
  && {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const Wrapper = styled('div')<WrapperProps>`
  display: ${({ open }) => (open ? `flex` : `none`)};
  margin: 0;
  padding: 0;
  background: #8360ca;
`;

export const Content = styled('div')`
  display: flex;
  margin: 0;
  padding: 2px 0 3px;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    margin: 0 4px;
    padding: 0 0 0 8px;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 80%;
      left: 0;
      top: 50%;
      bottom: 0;
      position: absolute;
      background: #7052ad;
      transform: translateY(-50%);
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.values.sm}px) {
      margin: 0 4px 0 16px;
      padding: 0 0 0 16px;
    }

    > p {
      display: block;
      margin: 0 0 0 10px;
      padding: 0;
      color: #fafafa;
      font-size: 11px;
      line-height: 1.35;
      letter-spacing: 0.2px;

      @media screen and (min-width: ${({ theme }) =>
          theme.breakpoints.values.sm}px) {
        font-size: 12px;
      }

      > code {
        display: inline-block;
        bottom: 1px;
        margin: 0;
        padding: 0 2px;
        font-weight: 600;
        position: relative;
      }
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    display: inline-flex;
    margin: 0 0 0 2px;
    padding: 4px;
    z-index: 1200;
  }
`;

export const CloseIcon = styled(Close)`
  && {
    color: #fff;
    width: 14px;
    height: 14px;
  }
`;

export const WarningAmberIcon = styled(WarningAmber)`
  && {
    color: #fff;
    width: 18px;
    height: 18px;
  }
`;
