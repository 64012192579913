import React from 'react';
import { InputLabelProps } from '@mui/material/InputLabel';

import { StyledInputLabel as MuiInputLabel } from './Label.styles';
//
type Props = InputLabelProps;

const InputLabel = ({ ...props }: Props) => {
  return (
    <>
      <MuiInputLabel shrink {...props} />
    </>
  );
};

InputLabel.defaultProps = {
  variant: 'standard',
  disableAnimation: true,
};

export default InputLabel;
