import { ethers } from 'ethers';
import { BigNumber } from 'mathjs';

import { mathjs } from './math';

//
export const getMaxInteger = () => ethers.constants.MaxUint256;

//
export const customFromWei = (number: BigNumber, decimal = 18): string => {
  return mathjs
    .chain(mathjs.bignumber(number.toString()))
    .divide(mathjs.pow(10, decimal))
    .done()
    .toFixed();
};

//
export const customToWei = (number: string, decimal = 18): string => {
  return mathjs
    .chain(mathjs.bignumber(number))
    .multiply(mathjs.pow(10, decimal))
    .done()
    .toFixed();
};
