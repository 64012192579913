import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

//
type Props = {
  active?: boolean;
} & SvgIconProps;

const SvgStakeIco: React.FC = ({ active }: Props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{
        fill: 'none',
        stroke: active ? '#00caef' : '#e0e0e0',
        strokeWidth: 1.4,
        strokeMiterlimit: 10,
      }}
      d="M8,11.9h3.6 M11.6,10.1H8 M12.8,9.2c0-0.7-0.5-1.2-1.2-1.2h-1.2C9.7,8,9.2,8.5,9.2,9.2v3.6
	c0,0.7,0.5,1.2,1.2,1.2h1.2c0.7,0,1.2-0.5,1.2-1.2 M11,2c5,0,9,4,9,9c0,2.5-1,5-2.6,6.6h2 M11,20c-5,0-9-4-9-9c0-2.5,1-5,2.6-6.6h-2
	 M11,17.6c-3.6,0-6.6-3-6.6-6.6s3-6.6,6.6-6.6s6.6,3,6.6,6.6S14.6,17.6,11,17.6z"
    />
  </SvgIcon>
);

export { SvgStakeIco };
