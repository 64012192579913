import React from 'react';
import { FormHelperTextProps } from '@mui/material/FormHelperText';
import { StyledFormHelperText as MuiFormHelperText } from './HelperText.styles';
//
type Props = FormHelperTextProps;

const HelperText = ({ ...props }: Props) => {
  return (
    <>
      <MuiFormHelperText {...props} />
    </>
  );
};

HelperText.defaultProps = {
  variant: 'standard',
};

export default HelperText;
