import { useContext, useEffect } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';

import { useWeb3 } from 'hooks/useWeb3';
import { SnackbarContext } from 'components/Snackbar';

//
export function useInactiveListeners(
  suppress: boolean,
  injectedConnector?: InjectedConnector,
) {
  const { activate } = useWeb3();
  const { openSnackbar } = useContext(SnackbarContext);

  useEffect((): any => {
    const { ethereum } = window as any;

    if (injectedConnector && ethereum && ethereum.on) {
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        if (!!suppress) {
          activate(injectedConnector, undefined, true).catch((error) => {
            console.dir('Failed to activate after chain changed', error);
            openSnackbar(
              'error',
              'Connection Error',
              'We could not connect to your wallet on selected network. Please try again.',
              false,
            );
          });
        }
      };

      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injectedConnector, undefined, true)
            .then(() => {
              openSnackbar(
                'success',
                'Connection Success',
                'We connected to your wallet successfully!',
              );
            })
            .catch((error) => {
              console.dir('Failed to activate after accounts changed', error);
              if (
                error.name === 't' ||
                error.name === 'UnsupportedChainIdError'
              ) {
                openSnackbar(
                  'warning',
                  'Wrong Network',
                  'We could not connect to your wallet on selected network. Please follow the instructions.',
                  false,
                );
              } else {
                openSnackbar(
                  'error',
                  'Connection Error',
                  'We could not connect to your wallet after account changed. Please try again.',
                  false,
                );
              }
            });
        }
      };

      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate, suppress, injectedConnector]);
}
