import React, { useCallback, useEffect, useState } from 'react';
import { Contract } from 'ethers';

import { useProvider, useWeb3 } from 'hooks';
import { StakingContract, ColonyERC20Token } from 'ethereum/contracts';

import { StakingBox, RewardsBox } from './components';
import { Title, StyledGrid as Grid, StyledBox as Box } from './Staking.styles';

//
export const StakingPage = () => {
  const { account } = useWeb3();
  const provider = useProvider();

  // State
  const [stakedAmount, setStakedAmount] = useState('0');
  const [balanceAmount, setBalanceAmount] = useState('0');
  const [totalStakedAmount, setTotalStakedAmount] = useState('0');
  const [authorizedStakeAmount, setAuthorizedStakeAmount] = useState('0');
  const [nativeTokenBalanceAmount, setNativeBalanceAmount] = useState('0');
  const [loadedAllRequired, setLoadedAllRequired] = useState<
    undefined | boolean
  >(undefined);

  // Methods
  const getNativeTokenBalance = async () => {
    const amount = await provider.getBalance(account);
    setNativeBalanceAmount(amount.toString());
  };

  const getBalanceAmount = async () => {
    const contract = new Contract(
      ColonyERC20Token.address,
      ColonyERC20Token.abi,
      provider,
    );

    const amount = await contract.balanceOf(account);
    setBalanceAmount(amount.toString());
  };

  const getStakedAmount = async () => {
    const contract = new Contract(
      StakingContract.address,
      StakingContract.abi,
      provider,
    );

    const amount = await contract.stakedBalanceOf(account);
    setStakedAmount(amount.toString());
  };

  const getTotalStakedAmount = async () => {
    const contract = new Contract(
      StakingContract.address,
      StakingContract.abi,
      provider,
    );

    const amount = await contract.totalStaked();
    setTotalStakedAmount(amount.toString());
  };

  const getAuthorizedStakeAmount = async () => {
    const contract = new Contract(
      StakingContract.address,
      StakingContract.abi,
      provider,
    );

    const authorizedStakeAmount = await contract.authorizedStakeAmount();
    setAuthorizedStakeAmount(authorizedStakeAmount);
  };

  const fetchAmounts = () => {
    Promise.all([
      getStakedAmount(),
      getBalanceAmount(),
      getTotalStakedAmount(),
      getNativeTokenBalance(),
      getAuthorizedStakeAmount(),
    ]).then(() => {
      setLoadedAllRequired(true);
    });
  };

  const resetDefaultState = useCallback(() => {
    setStakedAmount('0');
    setBalanceAmount('0');
    setTotalStakedAmount('0');
    setNativeBalanceAmount('0');
    setAuthorizedStakeAmount('0');
    setLoadedAllRequired(undefined);
  }, []);

  // Effects
  useEffect(() => {
    if (account) {
      setLoadedAllRequired(false);
      fetchAmounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (account === undefined && loadedAllRequired) {
      resetDefaultState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (account) {
      const contract = new Contract(
        StakingContract.address,
        StakingContract.abi,
        provider,
      );

      contract.on(contract.filters.StakeAdded(account), () => {
        fetchAmounts();
      });
      contract.on(contract.filters.StakeRemoved(account), () => {
        fetchAmounts();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Box>
      <Grid container>
        <Grid item xs={16}>
          <Title>
            <img src="/colony-mark.svg" alt="Staking" />
            <h2>Stake</h2>
          </Title>
        </Grid>

        <Grid container spacing={2} columns={16}>
          <Grid item xs={16} sm={16} md={16} lg={10}>
            <StakingBox
              balance={balanceAmount}
              stakedAmount={stakedAmount}
              loadedAllRequired={loadedAllRequired}
              nativeTokenBalance={nativeTokenBalanceAmount}
              authorizedStakeAmount={authorizedStakeAmount}
              fetchAmounts={fetchAmounts}
            />
          </Grid>

          <Grid item xs={16} sm={16} md={16} lg={6}>
            <RewardsBox totalStakedAmount={totalStakedAmount} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
