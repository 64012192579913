import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

type Props = {
  active?: boolean;
} & SvgIconProps;

//
const SvgIndexIco: React.FC = ({ active }: Props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <polygon
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        points="3.8,20 3.8,3.2 9.8,3.2 9.8,2 12.2,2 12.2,3.2 18.2,3.2 18.2,20 	"
      />
      <polyline
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        points="8.6,3.2 8.6,5.6 13.4,5.6 13.4,3.2 	"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="6.5"
        y1="8"
        x2="15.5"
        y2="8"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="6.5"
        y1="11"
        x2="15.5"
        y2="11"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="6.5"
        y1="14"
        x2="15.5"
        y2="14"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="6.5"
        y1="17"
        x2="15.5"
        y2="17"
      />
    </g>
  </SvgIcon>
);

export { SvgIndexIco };
