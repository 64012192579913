import React, { useCallback } from 'react';
import { utils } from 'ethers';
import { bignumber } from 'mathjs';

import {
  Title,
  Wrapper,
  BoxRow,
  List,
  ListItem,
  BoxTitle,
  BoxLabel,
  BoxValue,
  BoxHeader,
  BoxContent,
  ListContent,
  StyledBox as Box,
  StyledPaper as Paper,
  StyledTypography as Typography,
} from './Rewards.styles';

//
type Props = {
  totalStakedAmount: string;
};

export const RewardsBox = ({ totalStakedAmount }: Props) => {
  //
  const getValuePerToken = useCallback(
    (tokens: string) => {
      if (Number(totalStakedAmount) > 0) {
        const value = bignumber(utils.parseEther(tokens).toString())
          .div(bignumber(totalStakedAmount))
          .toString();

        return value
          ? parseFloat(value)
              .toFixed(3)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          : '0';
      }
      return '0';
    },
    [totalStakedAmount],
  );

  return (
    <Wrapper>
      <Paper>
        <Title>
          <Typography variant="h3">Colony Staking Rewards</Typography>
        </Title>

        <Box>
          <BoxHeader>
            <h4>Avax Staking Rewards</h4>
            <span></span>
          </BoxHeader>

          <BoxContent>
            {/* <BoxRow>
              <BoxLabel>Number of AVAX available: </BoxLabel>
              <BoxValue>50.2220</BoxValue>
            </BoxRow>
            <BoxRow>
              <BoxLabel>Value per CLY:</BoxLabel>
              <BoxValue>12.3001</BoxValue>
            </BoxRow> */}
            <p>
              The Validator Program is under development. The rewards will be
              available early 2022.
            </p>
          </BoxContent>
        </Box>

        <Box>
          <BoxHeader>
            <h4>Liquidity Provider Program Rewards</h4>
            <span></span>
          </BoxHeader>
          <BoxContent>
            <p>
              The Liquidity Providing Program has already begun. The rewards
              will be available early 2022.
            </p>
          </BoxContent>
        </Box>

        <Box>
          <BoxHeader>
            <h4>Project Airdrops</h4>
            <span></span>
          </BoxHeader>

          <ListContent>
            <List>
              <ListItem>
                <img src="/airdrops/talecraft.png" alt="" />
                <div>
                  <BoxTitle>TaleCraft</BoxTitle>
                  <BoxRow>
                    <BoxLabel>Token amount: </BoxLabel>
                    <BoxValue>222,307.69</BoxValue>
                  </BoxRow>
                  <BoxRow>
                    <BoxLabel>Value per CLY:</BoxLabel>
                    <BoxValue>{getValuePerToken('222307.69')}</BoxValue>
                  </BoxRow>
                </div>
              </ListItem>

              <ListItem>
                <img src="/airdrops/platypus.png" alt="" />
                <div>
                  <BoxTitle>Platypus</BoxTitle>
                  <BoxRow>
                    <BoxLabel>Token amount: </BoxLabel>
                    <BoxValue>625,000.00</BoxValue>
                  </BoxRow>
                  <BoxRow>
                    <BoxLabel>Value per CLY:</BoxLabel>
                    <BoxValue>{getValuePerToken('625000.00')}</BoxValue>
                  </BoxRow>
                </div>
              </ListItem>

              <ListItem>
                <img src="/airdrops/imperium.png" alt="" />
                <div>
                  <BoxTitle>Imperium Empires</BoxTitle>
                  <BoxRow>
                    <BoxLabel>Token amount: </BoxLabel>
                    <BoxValue>29,040,404.04</BoxValue>
                  </BoxRow>
                  <BoxRow>
                    <BoxLabel>Value per CLY:</BoxLabel>
                    <BoxValue>{getValuePerToken('29040404.04')}</BoxValue>
                  </BoxRow>
                </div>
              </ListItem>

              <ListItem>
                <img src="/airdrops/ohfinance.png" alt="" />
                <div>
                  <BoxTitle>OH!Finance</BoxTitle>
                  <BoxRow>
                    <BoxLabel>Token amount: </BoxLabel>
                    <BoxValue>1,154,761.90</BoxValue>
                  </BoxRow>
                  <BoxRow>
                    <BoxLabel>Value per CLY:</BoxLabel>
                    <BoxValue>{getValuePerToken('1154761.90')}</BoxValue>
                  </BoxRow>
                </div>
              </ListItem>

              <ListItem>
                <img src="/airdrops/heroes.png" alt="" />
                <div>
                  <BoxTitle>Heroes of NFT</BoxTitle>
                  <BoxRow>
                    <BoxLabel>Token amount: </BoxLabel>
                    <BoxValue>600,000.00</BoxValue>
                  </BoxRow>
                  <BoxRow>
                    <BoxLabel>Value per CLY:</BoxLabel>
                    <BoxValue>{getValuePerToken('600000.00')}</BoxValue>
                  </BoxRow>
                </div>
              </ListItem>

              <ListItem>
                <img src="/airdrops/islander.png" alt="" />
                <div>
                  <BoxTitle>Islander</BoxTitle>
                  <BoxRow>
                    <BoxLabel>Token amount: </BoxLabel>
                    <BoxValue>50,000,000.00</BoxValue>
                  </BoxRow>
                  <BoxRow>
                    <BoxLabel>Value per CLY:</BoxLabel>
                    <BoxValue>{getValuePerToken('50000000.00')}</BoxValue>
                  </BoxRow>
                </div>
              </ListItem>

              <ListItem>
                <img src="/airdrops/kyte.png" alt="" />
                <div>
                  <BoxTitle>Kyte</BoxTitle>
                  <BoxRow>
                    <BoxLabel>Token amount: </BoxLabel>
                    <BoxValue>500,000.00</BoxValue>
                  </BoxRow>
                  <BoxRow>
                    <BoxLabel>Value per CLY:</BoxLabel>
                    <BoxValue>{getValuePerToken('500000.00')}</BoxValue>
                  </BoxRow>
                </div>
              </ListItem>
            </List>
          </ListContent>
        </Box>
      </Paper>
    </Wrapper>
  );
};
