import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

//
type Props = {
  active?: boolean;
} & SvgIconProps;

const SvgStakingProgramIco: React.FC = ({ active }: Props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <polygon
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        points="18.2,20 3.8,20 3.8,2 13.4,2 18.2,6.8 	"
      />
      <polyline
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        points="13.4,2 13.4,6.8 18.2,6.8 	"
      />
      <path
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        d="M12.8,10.4c0-0.7-0.5-1.2-1.2-1.2h-1.2c-0.7,0-1.2,0.5-1.2,1.2V14c0,0.7,0.5,1.2,1.2,1.2h1.2
		c0.7,0,1.2-0.5,1.2-1.2l0,0"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="11.6"
        y1="11.3"
        x2="8"
        y2="11.3"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="8"
        y1="13.1"
        x2="11.6"
        y2="13.1"
      />
    </g>
  </SvgIcon>
);

export { SvgStakingProgramIco };
