import { useEffect, useState } from 'react';
import { InjectedConnector } from '@web3-react/injected-connector';

import { useWeb3 } from 'hooks/useWeb3';

//
export function useEagerConnect(
  injectedConnector?: InjectedConnector,
): boolean {
  //
  const { activate, active } = useWeb3();
  const [tried, setTried] = useState(false);

  useEffect(() => {
    if (injectedConnector) {
      injectedConnector.isAuthorized().then((authorized) => {
        if (authorized) {
          activate(injectedConnector, undefined, true).catch(() => {
            setTried(true);
          });
        } else {
          setTried(true);
        }
      });
    }
  }, [activate, injectedConnector]);

  useEffect(() => {
    if (active) {
      setTried(active);
    }
  }, [active]);

  return tried;
}
