import { styled } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';

export const Wrapper = styled('div')`
  display: block;
  width: 100%;
`;

export const Content = styled('div')`
  display: block;
  margin: 0;
  padding: 3px;
  border-radius: 8px;
  border: solid 1px #3c3c3c;
  background-color: #272727;
  box-shadow: 0 3px 0 -2px #121212;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  && {
    height: 6px;
    border-radius: 3px;
    background-color: transparent;
  }
`;
