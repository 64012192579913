import { styled } from '@mui/material/styles';

type ValueProps = {
  unlocked?: boolean | number;
};

export const Wrapper = styled('div')`
  display: block;
  margin: 0 0 2.5rem;
`;

export const Details = styled('div')`
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled('span')`
  display: block;
  margin: 0 0 2px;
  padding: 0;
  color: #40d7f3;
  font-size: 12px;
  font-weight: 600;
`;

export const Value = styled('div')<ValueProps>`
  display: block;
  color: #40d7f3;
  font-size: 16px;
  font-weight: 500;

  span {
    color: ${({ unlocked }) => (unlocked ? `#40d7f3` : `#757575`)};
  }
`;

export const LinearContent = styled('div')`
  display: flex;
  width: 80%;
  margin: 8px 0;
`;
