import React, { useCallback, useEffect, useState } from 'react';
import { utils, BigNumber } from 'ethers';

import {
  HelperText,
  InputLabel,
  FormControl,
  OutlinedInput,
} from 'components/Input';
import { Modal } from 'components/Modal';
import { useStake, useUnstake, useWeb3 } from 'hooks';
import { TabPanel, Tabs } from 'components/Tabs';
import { PrimaryButton } from 'components/Button';
import { StakeStepper, UnstakeStepper } from 'components/Stepper';

import { Unlock } from './Unlock';
import {
  Title,
  Wrapper,
  StakedContent,
  BalanceContent,
  FormActionWrapper,
  FormControlWrapper,
  StyledPaper as Paper,
  StyledTypography as Typography,
} from './Staking.styles';

const defaultTabs = [
  {
    id: 0,
    name: 'Stake',
    label: 'Stake',
  },
  {
    id: 1,
    name: 'unstake',
    label: 'Unstake',
  },
];

//
type Props = {
  balance: string;
  stakedAmount: string;
  nativeTokenBalance: string;
  authorizedStakeAmount: string;
  loadedAllRequired: undefined | boolean;
  fetchAmounts: () => void;
};

export const StakingBox = ({
  balance,
  stakedAmount,
  nativeTokenBalance,
  loadedAllRequired,
  authorizedStakeAmount,
  fetchAmounts,
}: Props) => {
  const { account } = useWeb3();

  // State
  const [activeTab, setActiveTab] = useState(0);
  const [stakingModalOpen, setOpenStakingModal] = useState(false);
  const [unstakingModalOpen, setUnstakingModalOpen] = useState(false);

  const [stakeAmountValue, setStakeAmountValue] = useState('');
  const [stakeAmountBlur, setStakeAmountBlur] = useState(false);
  const [stakeValidationError, setStakeValidationError] = useState({
    error: false,
    message: '',
  });

  const [unstakeAmountValue, setUnstakeAmountValue] = useState('');
  const [unstakeAmountBlur, setUnstakeAmountBlur] = useState(false);
  const [unstakeValidationError, setUnstakeValidationError] = useState({
    error: false,
    message: '',
  });

  // Hooks
  const {
    error: stakeError,
    status: stakeStatus,
    loading: stakeLoading,
    canceled: stakeCanceled,
    stake,
  } = useStake();
  const {
    error: unstakeError,
    status: unstakeStatus,
    loading: unstakeLoading,
    canceled: unstakeCanceled,
    unstake,
  } = useUnstake();

  // Methods
  const initValidation = () => {
    if (BigNumber.from(nativeTokenBalance).lte(BigNumber.from(0))) {
      setStakeValidationError({
        error: true,
        message: 'Not AVAX enough.',
      });
      setUnstakeValidationError({
        error: true,
        message: 'Not AVAX enough.',
      });
    } else {
      if (BigNumber.from(balance).lte(BigNumber.from('0'))) {
        setStakeValidationError({
          error: true,
          message: `Not enough CLY to stake.`,
        });
      }
      if (BigNumber.from(stakedAmount).lte(BigNumber.from('0'))) {
        setUnstakeValidationError({
          error: true,
          message: `Not enough CLY to unstake.`,
        });
      }
    }
  };

  const stakeAmountValidation = (value: string) => {
    const decimalPart =
      value.split('.').length > 1 ? value.split('.').pop() : [];

    if (value !== '' && decimalPart) {
      const formatedValue = utils.parseEther(value);
      const minStakeToAuthorized = BigNumber.from(authorizedStakeAmount).sub(
        BigNumber.from(stakedAmount),
      );

      if (
        decimalPart.length <= 18 &&
        BigNumber.from(formatedValue).gt(BigNumber.from('0'))
      ) {
        if (BigNumber.from(nativeTokenBalance).lte(BigNumber.from('0'))) {
          setStakeValidationError({
            error: true,
            message: 'Not enough AVAX amount to stake.',
          });
        } else if (BigNumber.from(balance).lte(BigNumber.from('0'))) {
          setStakeValidationError({
            error: true,
            message: 'Not enough CLY amount to stake.',
          });
        } else if (BigNumber.from(formatedValue).gt(BigNumber.from(balance))) {
          setStakeValidationError({
            error: true,
            message: 'Stake CLY amount is higher than your wallet balance.',
          });
        } else if (
          BigNumber.from(stakedAmount).eq(BigNumber.from('0')) &&
          BigNumber.from(authorizedStakeAmount).gt(
            BigNumber.from(formatedValue),
          )
        ) {
          setStakeValidationError({
            error: true,
            message: 'Min. stake amount 50 tokens.',
          });
        } else if (
          BigNumber.from(minStakeToAuthorized).gt(BigNumber.from(formatedValue))
        ) {
          setStakeValidationError({
            error: true,
            message: `Min. stake amount is ${parseFloat(
              utils.formatUnits(minStakeToAuthorized),
            ).toFixed(4)}  tokens.`,
          });
        } else {
          setStakeValidationError({
            error: false,
            message: '',
          });
        }
      } else {
        setStakeValidationError({
          error: false,
          message: '',
        });
      }
    }
  };

  const unstakeAmountValidation = (value: string) => {
    const decimalPart =
      value.split('.').length > 1 ? value.split('.').pop() : [];

    if (value !== '' && decimalPart) {
      const formatedValue = utils.parseEther(value);
      const stakeLimit = BigNumber.from(stakedAmount).sub(
        BigNumber.from(authorizedStakeAmount),
      );

      if (
        decimalPart.length <= 18 &&
        BigNumber.from(formatedValue).gt(BigNumber.from('0'))
      ) {
        if (BigNumber.from(nativeTokenBalance).lte(BigNumber.from('0'))) {
          setUnstakeValidationError({
            error: true,
            message: 'Not enough AVAX amount to unstake.',
          });
        } else if (BigNumber.from(stakedAmount).lte(BigNumber.from('0'))) {
          setUnstakeValidationError({
            error: true,
            message: 'Not enough CLY amount to unstake.',
          });
        } else if (
          BigNumber.from(formatedValue).gt(BigNumber.from(stakedAmount))
        ) {
          setUnstakeValidationError({
            error: true,
            message: 'Unstake amount is higher than your CLY staked balance.',
          });
        } else if (
          BigNumber.from(formatedValue).lt(BigNumber.from(stakedAmount)) &&
          BigNumber.from(formatedValue).gt(BigNumber.from(stakeLimit))
        ) {
          setUnstakeValidationError({
            error: true,
            message: `Staked tokens amount will fall below the minimum amount. Unstake a maximum of ${parseFloat(
              utils.formatUnits(stakeLimit),
            ).toFixed(2)} tokens or all of your tokens.`,
          });
        } else {
          setUnstakeValidationError({
            error: false,
            message: '',
          });
        }
      } else {
        setUnstakeValidationError({
          error: false,
          message: '',
        });
      }
    }
  };

  const resetDefaultState = useCallback(() => {
    // State
    setActiveTab(0);
    setOpenStakingModal(false);
    setUnstakingModalOpen(false);

    setStakeAmountValue('');
    setStakeAmountBlur(false);
    setStakeValidationError({
      error: false,
      message: '',
    });

    setUnstakeAmountValue('');
    setUnstakeAmountBlur(false);
    setUnstakeValidationError({
      error: false,
      message: '',
    });
  }, []);

  // Handlers
  const handleTabChange = async (value: any) => {
    setActiveTab(value);
  };

  const handleOpenStakingModal = useCallback(() => {
    setOpenStakingModal(true);
  }, []);

  const handleCloseStakingModal = useCallback(() => {
    setOpenStakingModal(false);
  }, []);

  const handleOpenUnstakingModal = useCallback(() => {
    setUnstakingModalOpen(true);
  }, []);

  const handleCloseUnstakingModal = useCallback(() => {
    setUnstakingModalOpen(false);
  }, []);

  const handleStakeAmountValueBlur = () => {
    initValidation();
    setStakeAmountBlur(true);
  };

  const handleStakeAmountValueChange = (event: any) => {
    const { value } = event.target;
    const parsedValue = value
      .replace(/^\./g, '0.')
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');

    const decimalPart =
      parsedValue.split('.').length > 1 ? parsedValue.split('.').pop() : [];
    if (decimalPart.length <= 18) {
      setStakeAmountValue(parsedValue);
      stakeAmountValidation(parsedValue);
    }
  };

  const handleOnMaxStakeAmountClick = () => {
    setStakeAmountValue(utils.formatUnits(balance));
    stakeAmountValidation(utils.formatUnits(balance));
  };

  const handleUnstakeAmountValueChange = (event: any) => {
    const { value } = event.target;
    const parsedValue = value
      .replace(/^\./g, '0.')
      .replace(/[^0-9.]/g, '')
      .replace(/(\..*)\./g, '$1');

    const decimalPart =
      parsedValue.split('.').length > 1 ? parsedValue.split('.').pop() : [];
    if (decimalPart.length <= 18) {
      setUnstakeAmountValue(parsedValue);
      unstakeAmountValidation(parsedValue);
    }
  };

  const handleUnstakeAmountValueBlur = () => {
    initValidation();
    setUnstakeAmountBlur(true);
  };

  const handleOnMaxUnstakeAmountClick = () => {
    setUnstakeAmountValue(utils.formatUnits(stakedAmount));
    unstakeAmountValidation(utils.formatUnits(stakedAmount));
  };

  const handleOnStakeClick = async () => {
    handleOpenStakingModal();
    if (!stakeLoading) {
      await stake(stakeAmountValue).then(() => {
        fetchAmounts();
        setStakeAmountValue('');
        setUnstakeAmountValue('');
      });
    }
  };

  const handleOnUnstakeClick = async () => {
    handleOpenUnstakingModal();
    if (!unstakeLoading) {
      await unstake(unstakeAmountValue).then(() => {
        fetchAmounts();
        setStakeAmountValue('');
        setUnstakeAmountValue('');
      });
    }
  };

  // Effects
  useEffect(() => {
    if (account === undefined) {
      resetDefaultState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <>
      <Modal
        title="Staking Process"
        open={stakingModalOpen}
        onClose={handleCloseStakingModal}
      >
        <StakeStepper
          error={stakeError}
          status={stakeStatus}
          loading={stakeLoading}
          canceled={stakeCanceled}
          onClose={handleCloseStakingModal}
        />
      </Modal>

      <Modal
        title="Unstaking Process"
        open={unstakingModalOpen}
        onClose={handleCloseUnstakingModal}
      >
        <UnstakeStepper
          error={unstakeError}
          status={unstakeStatus}
          loading={unstakeLoading}
          canceled={unstakeCanceled}
          onClose={handleCloseUnstakingModal}
        />
      </Modal>

      <Wrapper>
        <Paper>
          <Title>
            <Typography variant="h3">Colony Staking</Typography>
            <Typography variant="h4">
              Stake your CLY to unlock all Colony features
            </Typography>
          </Title>

          <Unlock />

          <Tabs
            value={activeTab}
            tabs={defaultTabs}
            onChange={handleTabChange}
          />

          <TabPanel value={activeTab} index={0}>
            <BalanceContent>
              CLY Amount:{' '}
              <span>{parseFloat(utils.formatUnits(balance)).toFixed(4)}</span>
            </BalanceContent>

            <FormControlWrapper>
              <FormControl
                error={stakeAmountBlur && stakeValidationError.error}
              >
                <InputLabel
                  error={stakeAmountBlur && stakeValidationError.error}
                >
                  Amount to stake
                </InputLabel>
                <OutlinedInput
                  value={stakeAmountValue}
                  placeholder="Amount to stake"
                  error={stakeAmountBlur && stakeValidationError.error}
                  onBlur={handleStakeAmountValueBlur}
                  onChange={handleStakeAmountValueChange}
                  endAdornment={
                    <PrimaryButton
                      text="MAX"
                      disabled={!loadedAllRequired}
                      onClick={handleOnMaxStakeAmountClick}
                    />
                  }
                />
                {stakeAmountBlur && stakeValidationError.error && (
                  <HelperText
                    error={stakeAmountBlur && stakeValidationError.error}
                  >
                    {stakeValidationError.message}
                  </HelperText>
                )}
              </FormControl>
            </FormControlWrapper>

            <StakedContent>
              CLY Staked:{' '}
              <span>
                {parseFloat(utils.formatUnits(stakedAmount)).toFixed(4)}
              </span>
            </StakedContent>

            <FormActionWrapper>
              <PrimaryButton
                text="Stake"
                onClick={handleOnStakeClick}
                disabled={
                  !stakeAmountValue ||
                  !loadedAllRequired ||
                  stakeValidationError.error ||
                  parseFloat(stakeAmountValue) === 0
                }
              />
            </FormActionWrapper>
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <BalanceContent>
              CLY Amount:{' '}
              <span>{parseFloat(utils.formatUnits(balance)).toFixed(4)}</span>
            </BalanceContent>

            <FormControlWrapper>
              <FormControl
                error={unstakeAmountBlur && unstakeValidationError.error}
              >
                <InputLabel>Amount to unstake</InputLabel>
                <OutlinedInput
                  value={unstakeAmountValue}
                  placeholder="Amount to unstake"
                  error={unstakeAmountBlur && unstakeValidationError.error}
                  onBlur={handleUnstakeAmountValueBlur}
                  onChange={handleUnstakeAmountValueChange}
                  endAdornment={
                    <PrimaryButton
                      text="MAX"
                      disabled={!loadedAllRequired}
                      onClick={handleOnMaxUnstakeAmountClick}
                    />
                  }
                />
                {unstakeAmountBlur && unstakeValidationError.error && (
                  <HelperText
                    error={unstakeAmountBlur && unstakeValidationError.error}
                  >
                    {unstakeValidationError.message}
                  </HelperText>
                )}
              </FormControl>
            </FormControlWrapper>

            <StakedContent>
              CLY Staked:{' '}
              <span>
                {parseFloat(utils.formatUnits(stakedAmount)).toFixed(4)}
              </span>
            </StakedContent>

            <FormActionWrapper>
              <PrimaryButton
                text="Unstake"
                onClick={handleOnUnstakeClick}
                disabled={
                  !loadedAllRequired ||
                  !unstakeAmountValue ||
                  unstakeValidationError.error ||
                  parseFloat(unstakeAmountValue) === 0
                }
              />
            </FormActionWrapper>
          </TabPanel>
        </Paper>
      </Wrapper>
    </>
  );
};
