import { useCallback, useEffect, useState } from 'react';
import { ethers, Contract } from 'ethers';
import { secondsToHours } from 'date-fns';

import { useProvider, useWeb3 } from 'hooks';
import { StakingContract } from 'ethereum/contracts';
import { LinearProgress } from 'components/Progress';
import { Label, Value, Details, Wrapper, LinearContent } from './Unlock.styles';

//
export const Unlock = () => {
  // Hooks
  const { account } = useWeb3();
  const provider = useProvider();

  // State
  const [unlocked, setUnlocked] = useState(false);
  const [unlockingProgress, setUnlockingProgress] = useState(0);
  const [lockedPeriodInDays, setLockedPeriodInDays] = useState('0');
  const [authorizedStakeAmount, setAuthorizedStakeAmount] = useState('50');
  const [authorizedPeriodInDays, setAuthorizedPeriodInDays] = useState('20');

  // Methods
  const fetchData = async () => {
    const contract = new Contract(
      StakingContract.address,
      StakingContract.abi,
      provider,
    );

    const authorizedStakeAmount = await contract.authorizedStakeAmount();
    const authorizedStakePeriod = await contract.authorizedStakePeriod();
    const timeRemaining = await contract.timeRemainingAuthorization(account);

    const authorizedTimeRemaining = timeRemaining[0];
    const timeRemainingInSeconds = timeRemaining[1].toString();
    const parsedAuthorizedStakeAmount = parseFloat(
      ethers.utils.formatUnits(authorizedStakeAmount),
    ).toFixed();

    if (authorizedStakePeriod && timeRemaining && timeRemainingInSeconds) {
      const lockedPeriodInHours = secondsToHours(timeRemainingInSeconds);
      const authorizedPeriodInHours = secondsToHours(authorizedStakePeriod);

      const authorizedPeriodInDays = Math.round(authorizedPeriodInHours / 24);
      const lockedPeroidInHoursDiff =
        Number(authorizedPeriodInHours) - Number(lockedPeriodInHours);
      const lockedPeriodInDays = Math.round(
        lockedPeroidInHoursDiff / 24,
      ).toString();

      if (timeRemainingInSeconds === '0' && authorizedTimeRemaining === true) {
        setUnlocked(true);
        setUnlockingProgress(100);
        setAuthorizedStakeAmount(parsedAuthorizedStakeAmount);
        setLockedPeriodInDays(authorizedPeriodInDays.toString());
        setAuthorizedPeriodInDays(authorizedPeriodInDays.toString());
      } else if (
        timeRemainingInSeconds > '0' &&
        authorizedTimeRemaining === true
      ) {
        const progress =
          ((Number(authorizedStakePeriod) - Number(timeRemainingInSeconds)) /
            Number(authorizedStakePeriod)) *
          100;

        setUnlocked(false);
        setUnlockingProgress(progress);
        setLockedPeriodInDays(lockedPeriodInDays);
        setAuthorizedStakeAmount(parsedAuthorizedStakeAmount);
        setAuthorizedPeriodInDays(authorizedPeriodInDays.toString());
      } else {
        setUnlocked(false);
        setUnlockingProgress(0);
        setLockedPeriodInDays('0');
        setAuthorizedStakeAmount(parsedAuthorizedStakeAmount);
        setAuthorizedPeriodInDays(authorizedPeriodInDays.toString());
      }
    }
  };

  const resetDefaultState = useCallback(() => {
    setUnlocked(false);
    setUnlockingProgress(0);
    setLockedPeriodInDays('0');
    setAuthorizedStakeAmount('50');
    setAuthorizedPeriodInDays('20');
  }, []);

  // Effects
  useEffect(() => {
    if (account) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (account === undefined) {
      resetDefaultState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Wrapper>
      <Details>
        <div>
          <Label>{unlocked ? `Unlocked` : `Unlock in`}</Label>
          <Value unlocked={unlocked ? 1 : 0}>
            <span>{lockedPeriodInDays}/</span> {authorizedPeriodInDays} days
          </Value>
        </div>
        <div>
          <Label>Min. amount</Label>
          <Value>{authorizedStakeAmount} tokens</Value>
        </div>
      </Details>

      <LinearContent>
        <LinearProgress value={unlockingProgress} />
      </LinearContent>
    </Wrapper>
  );
};
