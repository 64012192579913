import React, { useState } from 'react';

import {
  Wrapper,
  Content,
  CloseIcon,
  WarningAmberIcon,
  StyledGrid as Grid,
  StyledContainer as Container,
  StyledIconButton as IconButton,
} from './Bookmark.styles';

//
export const BookmarkTopbar = () => {
  //
  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper open={open ? 1 : 0}>
      <Container>
        <Grid container columns={16}>
          <Grid item>
            <Content>
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>

              <div>
                <WarningAmberIcon />
                <p>
                  Always make sure the URL is <code>app.colonylab.io</code> -
                  bookmark it to be safe.
                </p>
              </div>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
};
