import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 175px;
    color: #9e9e9e;
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: -0.2px;
    border-radius: 3px;
    border: solid 1px #333;
    background-color: #3c3c3c;
    box-shadow: 0 3px 0 -1px rgba(18, 18, 18, 0.35), inset 0 -3px 0 0 #616161,
      inset 0 -1px 0 1px #2c2c2c;

    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.values.sm}px) {
      min-width: 200px;
    }

    .MuiCircularProgress-root {
      color: #9e9e9e;
    }
  }
`;
