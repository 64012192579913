import { styled } from '@mui/material/styles';
import { Container, Grid } from '@mui/material';

//
export const MainContent = styled('main')``;

export const StyledGrid = styled(Grid)``;

export const StyledContainer = styled(Container)``;

export const AppLogo = styled('div')`
  display: flex;
  margin: 0;
  padding: 28px 0;
  position: relative;

  > img {
    display: block;
    margin: 0 14px;
    z-index: 20;
    width: 133px;
    height: auto;
  }

  &:after {
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    content: '';
    left: -999vw;
    position: absolute;
    background: #272727;
    border-bottom: 1px solid #1e1e1e;
  }
`;

export const Navigation = styled('nav')`
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #272727;

  &:after {
    top: 0;
    right: 0;
    z-index: 10;
    content: '';
    left: -999vw;
    bottom: 0;
    position: absolute;
    background: #272727;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.md}px) {
    &:after {
      bottom: -100vh;
    }
  }
`;

export const MenuContent = styled('div')`
  display: block;
  width: 100%;
  margin: 10px 0 0;
  padding: 0 15px;
  z-index: 20;
`;
