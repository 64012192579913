import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

//
type Props = {
  active?: boolean;
} & SvgIconProps;

const SvgEearlyStageIco: React.FC = ({ active }: Props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <circle
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        cx="11"
        cy="12.2"
        r="7.8"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="9.2"
        y1="2"
        x2="12.8"
        y2="2"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="11"
        y1="2"
        x2="11"
        y2="4.4"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="2.3"
        y1="6.6"
        x2="4.9"
        y2="4.1"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="3.6"
        y1="5.4"
        x2="5.3"
        y2="7.1"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="19.7"
        y1="6.6"
        x2="17.1"
        y2="4.1"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="18.4"
        y1="5.4"
        x2="16.7"
        y2="7.1"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="11"
        y1="6.8"
        x2="11"
        y2="12.8"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="2.3"
        y1="6.6"
        x2="4.9"
        y2="4.1"
      />
      <line
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        x1="3.6"
        y1="5.4"
        x2="5.3"
        y2="7.1"
      />
    </g>
  </SvgIcon>
);

export { SvgEearlyStageIco };
