import { styled } from '@mui/material/styles';
import { styled as styledd } from '@mui/material/styles';

type WalletOptionProps = {
  disabled?: boolean | number;
};

export const Wrapper = styledd('div')`
  display: block;
  width: 210px;
  margin: 30px 0 20px;
  padding: 0;

  @media screen and (min-width: 360px) {
    width: 250px;
  }

  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.values.sm}px) {
    width: 290px;
  }
`;

export const WalletList = styled('ul')`
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const WalletOption = styled('li')<WalletOptionProps>`
  display: flex;
  width: 100%;
  margin: 0 0 12px;
  padding: 4px 0;
  min-height: 40px;
  cursor: pointer;
  border-radius: 4px;
  align-items: center;
  background: #3c3c3c;

  ${({ disabled }) => disabled && `pointer-events: none;`};

  span {
    display: block;
    margin: 0 10px;
    color: #bdbdbd;
    font-size: 12px;
    font-weight: 500;
    ${({ disabled }) => disabled && `opacity: 0.5;`};
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const IconContent = styled('div')`
  display: flex;
  margin: 0 4px;
  padding: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: #fff;
  position: relative;
  border-radius: 1px;
  align-items: center;
  justify-content: center;

  img {
    max-width: 32px;
    height: auto;
  }

  &:hover {
    opacity: 0.95;
  }

  &:after {
    display: block;
    content: '';
    width: 38px;
    height: 38px;
    position: absolute;
    border-radius: 4px;
    border: 2px solid #272727;
  }
`;

export const ConnectedContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const ConnectedDetails = styledd('div')`
  display: flex;
  margin: 0 0 30px;
  padding: 0;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.values.sm}px) {
    flex-direction: row;
  }
`;

export const ConnectedLeft = styled('div')`
  display: flex;
  margin: 0;
  padding: 10px 14px 8px;
  position: relative;
  border-left: 1px solid #333;

  span {
    color: #40d7f3;
  }

  &:after {
    display: block;
    content: '';
    left: -1px;
    right: 3px;
    bottom: -4px;
    height: 2px;
    background: #333;
    position: absolute;
  }
`;

export const ConnectedRight = styled(ConnectedLeft)`
  &:after {
    display: block;
    content: '';
    left: -1px;
    right: -1px;
    bottom: -4px;
    height: 2px;
    background: #333;
    position: absolute;
  }
`;

export const DisconnectContent = styled('div')`
  display: flex;
  width: 100%;

  > .MuiButton-root {
    margin: 0 auto;
    width: 60%;
  }
`;

export const LoaderContent = styled('div')`
  display: flex;
  margin: 2rem 0;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    margin: 0 4px;
    padding: 0;
    font-size: 16px;
  }
`;
