import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

//
type Props = {
  active?: boolean;
} & SvgIconProps;

const SvgPortfolioIco: React.FC = ({ active }: Props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{
        fill: 'none',
        stroke: active ? '#00caef' : '#e0e0e0',
        strokeWidth: 1.4,
        strokeMiterlimit: 10,
      }}
      d="M6.2,2l0,0.6l2.4,3v1.2l-3.8,3.8c-1,1-1.6,2.4-1.6,3.9c0,3,2.5,5.5,5.5,5.5h4.6c3,0,5.5-2.5,5.5-5.5
	c0-1.5-0.6-2.9-1.6-3.9l-3.8-3.8V5.6l2.4-3l0-0.6H6.2z M8.6,9.8L11,7.4l2.4,2.4 M6.8,6.2h8.4"
    />
  </SvgIcon>
);

export { SvgPortfolioIco };
