import React, { createContext, useState } from 'react';
import { AlertColor } from '@mui/material';

import { Snackbar } from './Snackbar';

//
const defaultSnack = {
  message: '',
  open: false,
  closable: true,
  status: 'success',
};

const defaultAlertColor: AlertColor = 'success';

type Props = {
  children: React.ReactElement;
};

export const SnackbarContext = createContext({
  snackbar: defaultSnack,
  openSnackbar: (
    status: AlertColor,
    title: string,
    message: string,
    closable?: boolean,
  ) => {},
});

export function SnackbarProvider({ children }: Props): React.ReactElement {
  //
  const [snackbar, setSnackbar] = useState({
    open: false,
    title: '',
    message: '',
    closable: true,
    status: defaultAlertColor,
  });

  const openSnackbar = (
    status: AlertColor,
    title: string,
    message: string,
    closable = true,
  ) => {
    setSnackbar({
      title,
      status,
      message,
      closable,
      open: true,
    });
  };

  const onSnackbarClose = () => {
    setSnackbar({
      title: '',
      message: '',
      open: false,
      closable: false,
      status: defaultAlertColor,
    });
  };

  return (
    <SnackbarContext.Provider value={{ snackbar, openSnackbar }}>
      <Snackbar
        open={snackbar.open}
        title={snackbar.title}
        status={snackbar.status}
        message={snackbar.message}
        closable={snackbar.closable}
        onClose={onSnackbarClose}
      />
      {children}
    </SnackbarContext.Provider>
  );
}
