import {
  Route,
  Routes,
  Navigate,
  BrowserRouter as Router,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ThemeProvider } from '@mui/material/styles';
import { css, GlobalStyles } from '@mui/material';

import { theme } from 'utils/theme';
import { Layout } from 'components/Layout';
import { StakingPage } from 'pages/Staking';
import { WalletConfig } from 'types/WalletConfig';
import { SnackbarProvider } from 'components/Snackbar';
import { WalletProvider } from 'components/WalletProvider';

//
const METAMASK_CONFIG: WalletConfig<InjectedConnector> = {
  label: 'MetaMask',
  name: 'wallet.wallets.metamask',
  icon: '/metamask-logo.svg',
  connector: new InjectedConnector({
    supportedChainIds: [Number(process.env.REACT_APP_CHAIN_ID)],
  }),
  beforeConnect: async () => {
    const metamaskInstalled = !!(window as any).ethereum?.isMetaMask;

    if (!metamaskInstalled) {
      window.open('https://metamask.io', '_blank');
      return false;
    }

    return true;
  },
};

const WALLET_CONNECT_CONFIG: WalletConfig<WalletConnectConnector> = {
  disabled: false,
  label: 'Wallet Connect',
  name: 'wallet.wallets.walletconnect',
  icon: '/walletconnect-logo.svg',
  connector: new WalletConnectConnector({
    rpc: { 43114: 'https://api.avax.network/ext/bc/C/rpc' },
    qrcode: true,
    bridge: 'https://bridge.walletconnect.org',
  }),
  beforeConnect: async (connector) => {
    if (connector.walletConnectProvider?.wc?.uri) {
      connector.walletConnectProvider = undefined;
    }

    return true;
  },
};

export const AppRoutes = () => (
  <Router>
    <Layout>
      <Routes>
        <Route path="/stake" element={<StakingPage />} />
        <Route path="*" element={<Navigate replace to="/stake" />} />
      </Routes>
    </Layout>
  </Router>
);

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyles
        styles={css`
          body {
            color: #8f8f8f;
            font-size: 12px;
            font-family: 'Barlow', sans-serif;
          }

          #root {
            display: flex;
            flex-direction: column;
            background: #222;
            min-height: 100vh;
            overflow-x: hidden;
            overflow-y: hidden;
          }
        `}
      />
      <SnackbarProvider>
        <WalletProvider
          walletsConfig={[METAMASK_CONFIG, WALLET_CONNECT_CONFIG]}
        >
          {AppRoutes}
        </WalletProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};
