import React from 'react';

import {
  CloseIcon,
  StyledDialog as Dialog,
  StyledDialogTitle as DialogTitle,
  StyledDialogContent as DialogContent,
} from './Modal.styles';

type Props = {
  open: boolean;
  title: string;
  children: React.ReactNode;
  onClose: () => void;
};

//
export const Modal = ({ children, title, open, onClose }: Props) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <span>{title}</span>
        <CloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
