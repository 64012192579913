import { useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Contract } from 'ethers';

import { theme } from 'utils/theme';
import { Account } from 'components/Account';
import { useProvider, useWeb3 } from 'hooks';
import { PrimaryButton } from 'components/Button';
import { VestedBalance } from 'components/Vested';
import { ColonyERC20Token } from 'ethereum/contracts';
import { getWalletName } from 'helpers/getWalletName';
import { ClaimModal, WalletModal } from 'components/Modal';
import {
  AppLogo,
  LeftContent,
  RightContent,
  StyledGrid as Grid,
  StyledAppBar as AppBar,
  StyledToolbar as Toolbar,
  StyledMenuIcon as MenuIcon,
} from './Header.styles';

//
type Props = {
  onOpenDrawer: () => void;
};

export const Header = ({ onOpenDrawer }: Props) => {
  // Hooks
  const provider = useProvider();
  const { account, connector } = useWeb3();
  const isMdView = useMediaQuery(theme.breakpoints.up('md'));

  // State
  const [balanceAmount, setBalanceAmount] = useState('0');
  const [claimModalOpen, setClaimModalOpen] = useState(false);
  const [walletModalOpen, setWalletModalOpen] = useState(false);

  // Handlers
  const handleOpenWalletModal = useCallback(() => {
    setWalletModalOpen(true);
  }, []);

  const handleCloseWalletModal = useCallback(() => {
    setWalletModalOpen(false);
  }, []);

  const handleOpenClaimModal = useCallback(() => {
    setClaimModalOpen(true);
  }, []);

  const handleCloseClaimModal = useCallback(() => {
    setClaimModalOpen(false);
  }, []);

  // Methods
  const getBalanceAmount = async () => {
    const contract = new Contract(
      ColonyERC20Token.address,
      ColonyERC20Token.abi,
      provider,
    );

    const amount = await contract.balanceOf(account);
    setBalanceAmount(amount.toString());
  };

  const fetchAmounts = () => {
    getBalanceAmount();
  };

  // Effects
  useEffect(() => {
    if (account) {
      fetchAmounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Grid container>
      <Grid item xs={16}>
        <AppBar position="static">
          <Toolbar>
            <LeftContent>
              {!isMdView && (
                <>
                  <MenuIcon onClick={onOpenDrawer} />
                  <AppLogo>
                    <img src="/colony-logo.svg" alt="Colony" />
                  </AppLogo>
                </>
              )}
            </LeftContent>

            <RightContent>
              {/* {typeof account === 'string' && isMdView && <Unlock />} */}

              {typeof account === 'string' && (
                <VestedBalance
                  vestedAmount={balanceAmount}
                  onClick={handleOpenClaimModal}
                />
              )}

              {typeof account === 'string' && (
                <Account
                  address={account}
                  connector={connector}
                  onClick={handleOpenWalletModal}
                />
              )}

              {typeof account !== 'string' && (
                <PrimaryButton
                  text="Connect Wallet"
                  onClick={handleOpenWalletModal}
                />
              )}
            </RightContent>

            <WalletModal
              account={
                account
                  ? { address: account, wallet: getWalletName(connector) }
                  : null
              }
              open={walletModalOpen}
              onClose={handleCloseWalletModal}
            />

            <ClaimModal
              open={claimModalOpen}
              fetchAmounts={fetchAmounts}
              onClose={handleCloseClaimModal}
            />
          </Toolbar>
        </AppBar>
      </Grid>
    </Grid>
  );
};
