import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    display: none;
    margin: 0 16px;
    padding: 0;
    color: #e0e0e0;
    font-weight: 600;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    > span {
      display: block;
    }
  }
`;

export const IconContent = styled('div')`
  display: flex;
  margin: 0 4px;
  padding: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background: #fff;
  position: relative;
  border-radius: 1px;
  align-items: center;
  justify-content: center;

  img {
    max-width: 32px;
    height: auto;
  }

  &:hover {
    opacity: 0.95;
  }

  &:after {
    display: block;
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    border-radius: 4px;
    border: 1px solid #979797;
  }
`;
