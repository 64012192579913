import React from 'react';

import {
  StyledButton as Button,
  StyledCancelIcon as CancelIcon,
} from './Close.styles';

//
type Props = {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const CloseButton = ({ text, disabled, onClick }: Props) => {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      endIcon={<CancelIcon />}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
