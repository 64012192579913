import React, { ReactNode, useState } from 'react';
import { useMediaQuery } from '@mui/material';

import { theme } from 'utils/theme';
import { Header } from 'components/Header';
import { BookmarkTopbar } from 'components/Topbar';
import { Menu, Drawer } from 'components/Navigation';
import {
  AppLogo,
  Navigation,
  MenuContent,
  MainContent,
  StyledGrid as Grid,
  StyledContainer as Container,
} from './Layout.styles';

type Props = {
  children: ReactNode;
};

//
export const Layout = ({ children }: Props) => {
  // State
  const [openDrawer, setOpenDrawer] = useState(false);

  // Hooks
  const isMdView = useMediaQuery(theme.breakpoints.up('md'));

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <BookmarkTopbar />

      <Container disableGutters>
        <Grid container columns={16}>
          <Grid item xs={16} md={5} lg={4}>
            {isMdView && (
              <AppLogo>
                <img src="/colony-logo.svg" alt="Colony" />
              </AppLogo>
            )}

            <Navigation>
              {isMdView ? (
                <MenuContent>
                  <Menu />
                </MenuContent>
              ) : (
                <Drawer
                  open={openDrawer}
                  onOpen={handleOpenDrawer}
                  onClose={handleCloseDrawer}
                />
              )}
            </Navigation>
          </Grid>

          <Grid item xs={16} md={11} lg={12}>
            <Header onOpenDrawer={handleOpenDrawer} />
            <MainContent>{children}</MainContent>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
