import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#9184eb',
    },
    secondary: {
      main: '#20d1f1',
    },
    text: {
      primary: '#bdbdbd',
      secondary: '#757575',
    },
    error: {
      main: '#f2304d',
    },
  },
  typography: {
    h3: {
      color: '#9184eb',
      fontWeight: 500,
      fontSize: '1.3rem',
    },
    h4: {
      color: '#757575',
      fontSize: '1rem',
    },
    fontFamily: "'Barlow', sans-serif",
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: '0',
        },
      },
    },
  },
});
