import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

//
type Props = {
  active?: boolean;
} & SvgIconProps;

const SvgStudioIco: React.FC = ({ active }: Props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{
        fill: 'none',
        stroke: active ? '#00caef' : '#e0e0e0',
        strokeWidth: 1.4,
        strokeMiterlimit: 10,
      }}
      d="M20,3.2c0,0.7-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2S18.1,2,18.8,2S20,2.5,20,3.2z M20,18.8c0,0.7-0.5,1.2-1.2,1.2
	s-1.2-0.5-1.2-1.2s0.5-1.2,1.2-1.2S20,18.1,20,18.8z M4.4,18.8c0,0.7-0.5,1.2-1.2,1.2S2,19.5,2,18.8s0.5-1.2,1.2-1.2
	S4.4,18.1,4.4,18.8z M4.4,3.2c0,0.7-0.5,1.2-1.2,1.2S2,3.9,2,3.2S2.5,2,3.2,2S4.4,2.5,4.4,3.2z M15.3,6.8l2.5-2.5 M4.2,17.8l2.5-2.5
	 M15.3,15.2l2.5,2.5 M4.2,4.2l2.5,2.5 M17.6,11c0,3.6-3,6.6-6.6,6.6s-6.6-3-6.6-6.6s3-6.6,6.6-6.6S17.6,7.4,17.6,11z"
    />
  </SvgIcon>
);

export { SvgStudioIco };
