//
import { Menu } from './Menu';
import {
  Left,
  Right,
  Header,
  Wrapper,
  Content,
  AppLogo,
  StyledMenuIcon as MenuIcon,
  StyledSwipeableDrawer as SwipeableDrawer,
} from './Drawer.styles';

//
type Props = {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const Drawer = ({ open, onOpen, onClose }: Props) => (
  <SwipeableDrawer open={open} onClose={onClose} onOpen={onOpen}>
    <Wrapper>
      <Header>
        <Left>
          <MenuIcon onClick={onClose} />
          <AppLogo>
            <img src="/colony-logo.svg" alt="Colony" />
          </AppLogo>
        </Left>
        <Right></Right>
      </Header>

      <Content>
        <Menu />
        {/* <Unlock /> */}
      </Content>
    </Wrapper>
  </SwipeableDrawer>
);
