import { useCallback, useContext, useState } from 'react';
import { Contract } from 'ethers';

import { useProvider, useWeb3 } from 'hooks';
import { VestingContract } from 'ethereum/contracts';
import { SnackbarContext } from 'components/Snackbar';

export const CLAIM_STATUS = {
  INIT: { step: 0, status: 'init' },
  CONFIRMATION_NEEDED: { step: 0, status: 'confirmation_needed' },
  SUCCESS: { step: 1, status: 'success' },
  CANCELED: {
    step: 1,
    status: 'canceled',
  },
  ERROR: { step: 1, status: 'error' },
};

export function useClaim() {
  //
  const { account } = useWeb3();
  const provider = useProvider();
  const { openSnackbar } = useContext(SnackbarContext);

  const [error, setError] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);

  const claim = useCallback(async () => {
    try {
      if (!account) {
        throw new Error('No account connected');
      }

      setLoading(true);
      setCanceled(false);
      setError(undefined);
      setStatus(CLAIM_STATUS.INIT);

      setStatus(CLAIM_STATUS.CONFIRMATION_NEEDED);
      const contract = new Contract(
        VestingContract.address,
        VestingContract.abi,
        provider.getSigner(),
      );

      const claimTx = await contract.claimAll();
      await claimTx.wait();

      openSnackbar(
        'success',
        'Transaction Confirmed',
        'You can close transaction process.',
      );
      setStatus(CLAIM_STATUS.SUCCESS);
    } catch (error: any) {
      if (error.code === 4001) {
        openSnackbar(
          'warning',
          'Transaction Canceled',
          'You can close transaction process.',
        );
        setCanceled(error);
        setStatus(CLAIM_STATUS.CANCELED);
      } else {
        openSnackbar(
          'error',
          'Transaction Error',
          'Something went wrong. You can close transaction process.',
        );
        setError(error);
        setStatus(CLAIM_STATUS.ERROR);
      }
    } finally {
      setLoading(false);
    }
  }, [account, provider, openSnackbar]);

  return {
    error,
    status,
    loading,
    canceled,
    claim,
  };
}
