import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

//
type Props = {
  active?: boolean;
} & SvgIconProps;

const SvgLiquidityProgramIco: React.FC = ({ active }: Props) => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <g>
        <path
          style={{
            fill: 'none',
            stroke: active ? '#00caef' : '#e0e0e0',
            strokeWidth: 1.4,
            strokeMiterlimit: 10,
          }}
          d="M15.9,17c-0.3,0.6-0.7,1.2-1.3,1.6c-1,0.8-2.2,1.3-3.6,1.4c-1.4,0-2.7-0.5-3.6-1.4c-0.5-0.5-1-1-1.3-1.6
			c-0.8-1.5-0.7-3.4,0.1-4.9S11,4.6,11,2c0,2.6,4,8.6,4.8,10.1S16.7,15.5,15.9,17z"
        />
      </g>
      <path
        style={{
          fill: 'none',
          stroke: active ? '#00caef' : '#e0e0e0',
          strokeWidth: 1.4,
          strokeMiterlimit: 10,
        }}
        d="M13.9,14.8c0,1.6-1.3,2.9-2.9,2.9"
      />
    </g>
  </SvgIcon>
);

export { SvgLiquidityProgramIco };
