import { styled } from '@mui/material/styles';
import FormHelperText from '@mui/material/FormHelperText';

export const StyledFormHelperText = styled(FormHelperText)`
  && {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 0 0 20px;
    position: relative;
    font-size: 11px;
    font-weight: 600;

    &:before {
      display: block;
      width: 10px;
      height: 4px;
      content: '';
      top: 50%;
      left: 4px;
      bottom: 0;
      border-radius: 3px;
      position: absolute;
      background: #f2304d;
      transform: translateY(-50%);
    }
  }
`;
