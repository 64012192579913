import React, { useEffect, useState } from 'react';
import { bignumber } from 'mathjs';
import { ethers, Contract } from 'ethers';

import {
  StakingContract,
  VestingContract,
  ColonyERC20Token,
} from 'ethereum/contracts';
import { customFromWei } from 'helpers/format';
import { PrimaryButton } from 'components/Button';
import { ClaimStepper } from 'components/Stepper';
import { useClaim, useProvider, useWeb3 } from 'hooks';

import { Modal } from './Modal';
import {
  Header,
  Balance,
  Details,
  Actions,
  Content,
  Wrapper,
  DetailRow,
  DetailLabel,
  IconContent,
  DetailValue,
  DetailLabelLast,
  DetailValueLast,
} from './ClaimModal.styles';

//
export interface Props {
  open: boolean;
  onClose(): void;
  fetchAmounts: () => void;
}

export function ClaimModal({
  open,
  onClose,
  fetchAmounts,
}: Props): React.ReactElement {
  //
  const { account } = useWeb3();
  const provider = useProvider();
  const { error, loading, status, canceled, claim } = useClaim();

  // State
  const [vestedAmount, setVestedAmount] = useState('0');
  const [stakedAmount, setStakedAmount] = useState('0');
  const [balanceAmount, setBalanceAmount] = useState('0');
  const [vestingAmount, setVestingAmount] = useState('0');
  const [vestedAvailable, setVestedAvailable] = useState(false);
  const [claimProcessing, setClaimProcessing] = useState(false);

  // Methods
  const getBalanceAmount = async () => {
    const contract = new Contract(
      ColonyERC20Token.address,
      ColonyERC20Token.abi,
      provider,
    );

    const amount = await contract.balanceOf(account);
    setBalanceAmount(amount.toString());
  };

  const getStakedAmount = async () => {
    const contract = new Contract(
      StakingContract.address,
      StakingContract.abi,
      provider,
    );

    const amount = await contract.stakedBalanceOf(account);
    setStakedAmount(amount.toString());
  };

  const getVestedAmount = async () => {
    const contract = new Contract(
      VestingContract.address,
      VestingContract.abi,
      provider,
    );
    const amount = await contract.checkClaim(account);
    setVestedAmount(amount.toString());
  };

  const getVestedStatus = async () => {
    const contract = new Contract(
      VestingContract.address,
      VestingContract.abi,
      provider,
    );

    const vestingPaused = await contract.paused();
    const claimAmount = await contract.checkClaim(account);
    const vestingStarted = await contract.vestingStarted();
    const vestingCloseTimestamp = await contract.vestingCloseTimestamp();

    const claimAmountValue = claimAmount.toString();
    const currentTimestamp = Math.floor(new Date().valueOf() / 1000);
    const vestingCloseTimestampValue = vestingCloseTimestamp.toString();

    if (
      vestingStarted &&
      vestingPaused === false &&
      Number(claimAmountValue) > 0 &&
      (Number(vestingCloseTimestampValue) === 0 ||
        currentTimestamp < vestingCloseTimestampValue)
    ) {
      setVestedAvailable(true);
    }
  };

  const getVestAmount = async () => {
    const contract = new Contract(
      VestingContract.address,
      VestingContract.abi,
      provider,
    );

    const userConfiguration = await contract.userConfiguration(account);

    if (
      userConfiguration &&
      userConfiguration.vestAmount &&
      userConfiguration.withdrawnAmount
    ) {
      const vestingAmount = bignumber(
        userConfiguration.vestAmount.toString(),
      ).sub(bignumber(userConfiguration.withdrawnAmount.toString()));

      setVestingAmount(customFromWei(vestingAmount));
    }
  };

  const fetchData = () => {
    Promise.all([
      getVestAmount(),
      getVestedStatus(),
      getVestedAmount(),
      getStakedAmount(),
      getBalanceAmount(),
    ]);
  };

  // Handlers
  const handleOnClaim = async () => {
    setClaimProcessing(true);
    await claim().then(() => {
      fetchData();
      fetchAmounts();
    });
  };

  useEffect(() => {
    if (account) {
      fetchData();
      fetchAmounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (open && !loading) {
      setClaimProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (account) {
      const contract = new Contract(
        StakingContract.address,
        StakingContract.abi,
        provider,
      );

      contract.on(contract.filters.StakeAdded(account), () => {
        fetchData();
        fetchAmounts();
      });
      contract.on(contract.filters.StakeRemoved(account), () => {
        fetchData();
        fetchAmounts();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  useEffect(() => {
    if (account) {
      const contract = new Contract(
        VestingContract.address,
        VestingContract.abi,
        provider,
      );

      contract.on(contract.filters.TokensClaimed(), (claimedAccount) => {
        if (account === claimedAccount) {
          fetchData();
          fetchAmounts();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Modal title="Colony Balance" open={open} onClose={onClose}>
      <Wrapper>
        {claimProcessing ? (
          <ClaimStepper
            error={error}
            status={status}
            loading={loading}
            canceled={canceled}
            onClose={onClose}
          />
        ) : (
          <>
            <Content>
              <Header>
                <IconContent>
                  <img src="/colony-token.svg" alt="Colony token" />
                </IconContent>
                <Balance>
                  <span>
                    {parseFloat(
                      ethers.utils.formatUnits(balanceAmount),
                    ).toFixed(2)}
                  </span>
                </Balance>
                {/* <Value>
                  <span>$0.00</span>
                </Value> */}
              </Header>

              <Details>
                <tbody>
                  <DetailRow>
                    <DetailLabel>Wallet Balance</DetailLabel>
                    <DetailValue>
                      {parseFloat(
                        ethers.utils.formatUnits(balanceAmount),
                      ).toFixed(2)}
                    </DetailValue>
                  </DetailRow>

                  <DetailRow>
                    <DetailLabel>Unclaimed Balance</DetailLabel>
                    <DetailValue>
                      {parseFloat(
                        ethers.utils.formatUnits(vestedAmount),
                      ).toFixed(2)}
                    </DetailValue>
                  </DetailRow>

                  <DetailRow>
                    <DetailLabel>Vested Balance</DetailLabel>
                    <DetailValue>
                      {parseFloat(vestingAmount).toFixed(2)}
                    </DetailValue>
                  </DetailRow>

                  <DetailRow>
                    <DetailLabelLast>CLY Staked</DetailLabelLast>
                    <DetailValueLast>
                      {parseFloat(
                        ethers.utils.formatUnits(stakedAmount),
                      ).toFixed(2)}
                    </DetailValueLast>
                  </DetailRow>

                  {/* <DetailRow>
                  <DetailLabelLast>Price</DetailLabelLast>
                  <DetailValueLast>$6.34</DetailValueLast>
                </DetailRow> */}
                </tbody>
              </Details>

              <Actions>
                <PrimaryButton
                  disabled={!vestedAvailable || vestedAmount === '0'}
                  text={`Claim ${parseFloat(
                    ethers.utils.formatUnits(vestedAmount),
                  ).toFixed(3)} CLY`}
                  onClick={handleOnClaim}
                />
              </Actions>
            </Content>
          </>
        )}
      </Wrapper>
    </Modal>
  );
}
