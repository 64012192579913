import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledButton = styled(Button)`
  && {
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: -0.2px;
    border-radius: 3px;
    border: solid 1px #796bda;
    background-image: linear-gradient(285deg, #796bda, #8172e8);
    box-shadow: 0 3px 0 -1px rgba(18, 18, 18, 0.35), inset 0 -3px 0 0 #9184eb,
      inset 0 -1px 0 1px #7164cb;

    &.MuiButton-root.Mui-disabled {
      color: #9e9e9e;
      border: solid 1px #333;
      background-image: none;
      background-color: #3c3c3c;
      box-shadow: 0 3px 0 -1px rgba(18, 18, 18, 0.35), inset 0 -3px 0 0 #616161,
        inset 0 -1px 0 1px #2c2c2c;
    }
  }
`;
