import React from 'react';

import { StyledButton as Button } from './Primary.styles';

//
type Props = {
  text: string;
  disabled?: boolean;
  onClick: () => void;
};

export const PrimaryButton = ({ text, disabled, onClick }: Props) => {
  return (
    <Button variant="contained" disabled={disabled} onClick={onClick}>
      {text}
    </Button>
  );
};
