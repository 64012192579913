import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { utils } from 'ethers';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { InjectedConnector } from '@web3-react/injected-connector';

import { NETWORKS_MAP } from 'types/constants';
import { chainConfigs } from 'ethereum/chains';
import { WalletConfig } from 'types/WalletConfig';
import { WalletOption } from 'types/WalletOption';
import { SnackbarContext } from 'components/Snackbar';
import { useEagerConnect, useInactiveListeners, useWeb3 } from 'hooks';

//
type BeforeConnectFn = (connector: AbstractConnector) => Promise<boolean>;

export interface RenderProps {
  error?: string;
  loading: boolean;
}

export interface Props {
  desiredChainId?: string | number;
  walletsConfig: WalletConfig[];
  children(renderProps: RenderProps): React.ReactElement | null;
}

export interface WalletContextShape {
  options: WalletOption[];
}

export const WalletContext = createContext<WalletContextShape>({
  options: [],
});

export function Wallet({
  children: renderFn,
  walletsConfig,
  desiredChainId = process.env.REACT_APP_CHAIN_ID,
}: Props): React.ReactElement | null {
  //
  const { active, activate, chainId } = useWeb3();
  const { openSnackbar } = useContext(SnackbarContext);
  const [networkChanged, setNetworkChanged] = useState(false);
  const previousChainIdRef = useRef<number>();

  const connect = useCallback(
    async (connector: AbstractConnector, beforeConnect?: BeforeConnectFn) => {
      if (!connector) {
        return;
      }

      const shouldActivate = beforeConnect
        ? await beforeConnect(connector)
        : true;

      if (shouldActivate) {
        const provider = await new InjectedConnector({
          supportedChainIds: [Number(process.env.REACT_APP_CHAIN_ID)],
        }).getProvider();

        const selectedChain = chainConfigs.find(
          (chain) =>
            chain.chainId ===
            utils.hexlify(Number(process.env.REACT_APP_CHAIN_ID)),
        );

        try {
          await activate(connector, undefined, true);
          openSnackbar(
            'success',
            'Connection Success',
            'We connected to your wallet successfully!',
          );
        } catch (error: any) {
          console.dir(error);
          if (error.name === 't' || error.name === 'UnsupportedChainIdError') {
            openSnackbar(
              'warning',
              'Wrong Network',
              'We could not connect to your wallet on selected network. Please follow the instructions.',
              false,
            );

            try {
              await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: selectedChain?.chainId }],
              });

              await activate(connector, undefined, true);
              openSnackbar(
                'success',
                'Connection Success',
                'We connected to your wallet successfully!',
              );
            } catch (switchError: any) {
              console.dir(switchError);
              if (switchError.code === 4902) {
                try {
                  await provider.request({
                    method: 'wallet_addEthereumChain',
                    params: [selectedChain?.params],
                  });

                  await activate(connector, undefined, true);
                  openSnackbar(
                    'success',
                    'Connection Success',
                    'We connected to your wallet successfully!',
                  );
                } catch (addError) {
                  console.dir(addError);
                }
              }
            }
          }
        }
      }
    },
    [activate, openSnackbar],
  );

  const error = useMemo(() => {
    if (networkChanged) {
      return 'Your network has changed. Please refresh the page.';
    }

    if (
      active &&
      chainId &&
      chainId.toString() !== desiredChainId?.toString()
    ) {
      const currentNetworkName = NETWORKS_MAP[chainId.toString()] || 'Unknown';
      const desiredNetworkName =
        (desiredChainId && NETWORKS_MAP[desiredChainId.toString()]) ||
        'Unknown';

      return `We've detected that you need to switch your wallet's network from ${currentNetworkName} to ${desiredNetworkName} network.`;
    }

    const unknownChain = active && !chainId;
    const unsupportedChain =
      chainId && !Object.keys(NETWORKS_MAP).includes(chainId.toString());

    if (unknownChain || unsupportedChain) {
      return 'Unsupported network';
    }
  }, [active, chainId, desiredChainId, networkChanged]);

  const options = useMemo(() => {
    return walletsConfig.map<WalletOption>(
      ({ connector, label, name, disabled, icon, beforeConnect }) => {
        return {
          icon,
          name,
          label,
          disabled,
          connect: () => connect(connector, beforeConnect),
        };
      },
    );
  }, [connect, walletsConfig]);

  const injectedConnectorConfig = walletsConfig.find(
    (item) => item.connector instanceof InjectedConnector,
  );

  const injectedConnector =
    injectedConnectorConfig?.connector as InjectedConnector;

  const triedEager = useEagerConnect(injectedConnector);

  useInactiveListeners(!triedEager, injectedConnector);

  useEffect(() => {
    if (
      typeof previousChainIdRef.current !== 'undefined' &&
      typeof chainId !== 'undefined' &&
      previousChainIdRef.current !== chainId
    ) {
      setNetworkChanged(true);
    }

    previousChainIdRef.current = chainId;
  }, [chainId]);

  return (
    <WalletContext.Provider value={{ options }}>
      {renderFn({ error, loading: !triedEager })}
    </WalletContext.Provider>
  );
}
