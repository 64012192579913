import React from 'react';
import { AlertColor } from '@mui/material';

import {
  CloseIcon,
  StyledAlert as Alert,
  StyledSnackbar as MuiSnackbar,
  StyledIconButton as IconButton,
} from './Snkackbar.styles';

type Props = {
  open: boolean;
  title: string;
  message: string;
  closable: boolean;
  status: AlertColor;
  onClose?: () => void;
};

//
const Snackbar = ({
  open,
  status,
  title,
  message,
  closable,
  onClose,
}: Props) => {
  return (
    <MuiSnackbar
      open={open}
      onClose={closable ? onClose : undefined}
      autoHideDuration={closable ? 6000 : undefined}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {open ? (
        <Alert severity={status}>
          <h3>{title}</h3>
          <span>{message}</span>
          {!closable && (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Alert>
      ) : (
        <div></div>
      )}
    </MuiSnackbar>
  );
};

export { Snackbar };
