import React from 'react';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';

import { StyledOutlinedInput as MuiOutlinedInput } from './OutlinedInput.styles';

type Props = OutlinedInputProps;

const OutlinedInput = ({ ...props }: Props) => {
  return (
    <>
      <MuiOutlinedInput {...props} />
    </>
  );
};

OutlinedInput.defaultProps = {
  variant: 'outlined',
};

export default OutlinedInput;
