import { styled } from '@mui/material/styles';
import { Box, Grid } from '@mui/material';
import { styled as styledd } from '@mui/material/styles';

export const StyledGrid = styled(Grid)``;

export const Title = styled('div')`
  display: flex;
  margin: 22px 4px;

  h2 {
    display: block;
    margin: 0 0 0 16px;
    padding: 0;
    color: #10cdf0;
    font-weight: 500;
    font-size: 2rem;
  }

  img {
    width: 48px;
    height: auto;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const StyledBox = styledd(Box)`
  && {
    display: flex;
    margin: 0;
    padding: 0 24px;

    @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
      padding: 14px 28px;
    }
    
    @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.lg}px) {
      padding: 0 14px;
    }
  }
`;
