import { styled } from '@mui/material/styles';
import { Paper, Typography } from '@mui/material';
import { styled as styledd } from '@mui/material/styles';

export const Wrapper = styled('div')``;

export const StyledPaper = styledd(Paper)`
  && {
    margin: 0;
    padding: 12px 16px;
    background: #272727;
    border: solid 1px #2c2c2c;
    box-shadow: 0 8px 4px -3px #1e1e1e, 0 4px 0 -3px rgba(18, 18, 18, 0.35),
      inset 0 -1px 0 0 rgba(18, 18, 18, 0.2);

    @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.lg}px) {
          margin: 0 0 0 70px;
    }
  }
`;

export const Title = styled('div')`
  display: block;
  margin: 0 0 1.75rem;
`;

export const StyledTypography = styled(Typography)``;

export const FormControlWrapper = styled('div')`
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-between;

  > .MuiButton-root {
    width: auto;
    margin: 0 0 0 8px;
    bottom: 6px;
    align-self: flex-end;
  }
`;

export const FormActionWrapper = styled('div')`
  display: flex;
  width: 100%;
  margin: 2.5rem 0;
  padding: 0 4.5rem;
  align-items: center;
  justify-content: center;

  > .MuiButton-root {
    width: 100%;
  }
`;

export const BalanceContent = styled('div')`
  display: flex;
  margin: 1rem 0 0;
  padding: 0;
  color: #bdbdbd;
  font-size: 12px;
  font-weight: 500;
  top: 10px;
  position: relative;
  align-items: center;
  justify-content: flex-end;

  > span {
    display: inline-block;
    margin: 0 0 0 4px;
    color: #40d7f3;
  }
`;

export const StakedContent = styled('div')`
  display: flex;
  margin: 8px 0 1.5rem;
  padding: 0;
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  justify-content: flex-end;
`;
