import React, { useCallback, useContext, useState } from 'react';

import { useWeb3 } from 'hooks';
import { Loader } from 'components/Loader';
import { PrimaryButton } from 'components/Button';
import { WalletContext } from 'components/Wallet';

import { Modal } from './Modal';
import {
  Wrapper,
  WalletList,
  IconContent,
  WalletOption,
  LoaderContent,
  ConnectedLeft,
  ConnectedRight,
  ConnectedDetails,
  ConnectedContent,
  DisconnectContent,
} from './WalletModal.styles';

//
export interface Props {
  account?: {
    address: string;
    wallet: string | null;
  } | null;
  open: boolean;
  onClose(): void;
}

export function WalletModal({
  account,
  open,
  onClose,
}: Props): React.ReactElement {
  //
  // Hooks
  const { deactivate } = useWeb3();
  const walletOptions = useContext(WalletContext);

  // State
  const [connecting, setConnecting] = useState(false);

  // Handlers
  const handleConnectionClick = (connect: any) => async () => {
    setConnecting(true);
    await connect();

    setTimeout(() => {
      setConnecting(false);
      onClose();
    }, 1500);
  };

  const handleDisconnectWallet = useCallback(async () => {
    setConnecting(true);
    await deactivate();

    setTimeout(() => {
      onClose();
      setConnecting(false);
    }, 1500);
  }, [deactivate, onClose]);

  return (
    <Modal title="Select wallet" open={open} onClose={onClose}>
      <Wrapper>
        {connecting && (
          <LoaderContent>
            <Loader size={20} />
            <span>Loading...</span>
          </LoaderContent>
        )}

        {!account && !connecting && (
          <WalletList>
            {walletOptions.options.map((option) => (
              <WalletOption
                key={option.name}
                disabled={option.disabled}
                onClick={handleConnectionClick(option.connect)}
              >
                <IconContent>
                  <img src={option.icon} alt={option.label} />
                </IconContent>
                <span>{option.label}</span>
              </WalletOption>
            ))}
          </WalletList>
        )}

        {!!account && !connecting && (
          <ConnectedContent>
            <ConnectedDetails>
              <ConnectedLeft>{`Connected with ${account.wallet}`}</ConnectedLeft>
              <ConnectedRight>
                <span>
                  {`${account.address.slice(0, 6)} ... ${account.address.slice(
                    -4,
                  )}`}
                </span>
              </ConnectedRight>
            </ConnectedDetails>

            <DisconnectContent>
              <PrimaryButton
                text="Disconnect"
                onClick={handleDisconnectWallet}
              />
            </DisconnectContent>
          </ConnectedContent>
        )}
      </Wrapper>
    </Modal>
  );
}
