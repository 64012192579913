import React from 'react';
import { Box } from '@mui/material';

type Props = {
  index: number;
  value: number;
  children?: React.ReactNode;
};

const TabPanel = ({ children, value, index, ...other }: Props) => {
  const isActive = value === index;

  return (
    <div
      role="tabpanel"
      hidden={!isActive}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {isActive && <Box>{children}</Box>}
    </div>
  );
};

export default TabPanel;
