import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const StyledButton = styled(Button)`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 175px;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    letter-spacing: -0.2px;
    border-radius: 3px;
    border: 1px solid #796bda;
    background-image: linear-gradient(281deg, #796bda, #8172e8);
    box-shadow: 0 3px 0 -1px #796bda, inset 0 -3px 0 0 #9184eb,
      inset 0 -1px 0 1px #7164cb;

    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.values.sm}px) {
      min-width: 200px;
    }
  }
`;

export const StyledCancelIcon = styled(Close)``;
