import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';

export const StyledInputLabel = styled(InputLabel)`
  && {
    margin: 0 0 2px;
    padding: 0;
    color: #a195ee;
    position: static;
    transform: none;
    font-weight: 600;
    font-size: 0.8rem;

    &.MuiInputLabel-root.Mui-error {
      color: #f2304d;
    }
  }
`;
