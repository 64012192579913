import React from 'react';

import { StyledTabs as Tabs, StyledTab as Tab } from './Tabs.styles';

type Props = {
  tabs: { id: number; name: string; label: string }[];
  value: number;
  onChange: (newValue: number) => void;
};

const Tabss = ({ tabs, value, onChange }: Props) => {
  //

  const handleTabChange = (event: any, newValue: any) => {
    onChange(newValue);
  };

  return (
    <Tabs
      value={value}
      variant="fullWidth"
      onChange={handleTabChange}
      TabIndicatorProps={{ children: <span /> }}
    >
      {tabs.map((tab) => (
        <Tab key={tab.name} label={tab.label} />
      ))}
    </Tabs>
  );
};

export default Tabss;
