//
import { ERC20TokenABI, StakingABI, VestingABI } from './abi';

export const ColonyERC20Token = {
  abi: ERC20TokenABI,
  address: process.env.REACT_APP_CLY_TOKEN || '',
};

export const VestingContract = {
  abi: VestingABI,
  address: process.env.REACT_APP_VESTING_CONTRACT || '',
};

export const StakingContract = {
  abi: StakingABI,
  address: process.env.REACT_APP_STAKING_CONTRACT || '',
};
