import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTooltip = styled(Tooltip)``;

export const Content = styled('div')`
  display: block;
  margin: 0;
  padding: 14px;
  font-size: 13px;
  border-radius: 2px;
  letter-spacing: 0.2px;
  border: solid 1px #353535;
  background-color: #2e2e2e;
  box-shadow: 0 9px 12px 0 #212121, 0 3px 0 -2px #2c2c2c,
    inset 0 -1px 0 1px #353535;
`;
