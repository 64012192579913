import { styled } from '@mui/material/styles';
import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Grid, Toolbar } from '@mui/material';

export const StyledGrid = styled(Grid)``;

export const StyledAppBar = styled(AppBar)`
  && {
    display: flex;
    margin: 0;
    padding: 8px 14px;
    background: #222;
    box-shadow: none;
    position: relative;

    &:after {
      top: 0;
      bottom: 0;
      content: '';
      z-index: 10;
      left: 15px;
      right: -999vw;
      position: absolute;
      border-bottom: 1px solid #353535;
    }
  }
`;

export const StyledToolbar = styled(Toolbar)`
  && {
    display: flex;
    margin: 0;
    padding: 0;
    z-index: 20;
    align-items: center;
    justify-content: space-between;
  }
`;

export const LeftContent = styled('div')`
  display: flex;
  align-items: center;
`;

export const AppLogo = styled('div')`
  display: flex;
  margin: 0;
  padding: 24px 0 24px 10px;
  position: relative;
  border-bottom: 1px solid #1e1e1e;

  > img {
    display: none;
    margin: 0;
    z-index: 20;
    width: 100px;
    height: auto;
  }

  @media screen and (min-width: 380px) {
    > img {
      display: block;
    }
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.values.sm}px) {
    > img {
      display: block;
      width: 120px;
      margin: 0 4px;
    }
  }
`;

export const RightContent = styled('div')`
  display: flex;
`;

export const StyledMenuIcon = styled(MenuIcon)`
  && {
    width: 2rem;
    height: auto;
    color: #40d7f3;
  }
`;
