//
export const AvalancheMainnetConfig = {
  chainId: '0xa86a',
  params: {
    chainId: '0xa86a',
    chainName: 'Avalanche Mainnet C-Chain',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
};

export const AvalancheTestnetConfig = {
  chainId: '0xa869',
  params: {
    chainId: '0xa869',
    chainName: 'Avalanche FUJI C-Chain',
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/'],
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
};

export const chainConfigs = [AvalancheMainnetConfig, AvalancheTestnetConfig];
