import React from 'react';
import { ethers } from 'ethers';

import { Wrapper, IconContent, Label, ArrowIcon } from './Vested.styles';

//
type Props = {
  vestedAmount: string;
  onClick: () => void;
};

export const VestedBalance = ({ vestedAmount, onClick }: Props) => {
  //
  return (
    <>
      <Label>
        <span>Claim here </span>
        <ArrowIcon />
      </Label>

      <Wrapper onClick={onClick}>
        <IconContent>
          <img src="/colony-token.svg" alt="Colony token" />
        </IconContent>

        <span>
          {parseFloat(ethers.utils.formatUnits(vestedAmount)).toFixed(3)}
        </span>
      </Wrapper>
    </>
  );
};
