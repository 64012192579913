import React, { useState, useEffect } from 'react';

import { CloseButton, ProcessingButton } from 'components/Button';
import {
  StatusBox,
  StepDescription,
  ClaimWrapper as Wrapper,
  StyledStepper as Stepper,
  StyledStepLabel as StepLabel,
  StyledStepContent as StepContent,
  StyledStepperStep as StepperStep,
} from './Stepper.styles';

type Props = {
  status: any;
  error: boolean;
  loading: boolean;
  canceled: boolean;
  onClose: () => void;
};

//
export const ClaimStepper = ({
  error,
  status,
  loading,
  canceled,
  onClose,
}: Props) => {
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setActiveStep(status.step);
  }, [status]);

  return (
    <Wrapper>
      <Stepper activeStep={activeStep} orientation="vertical">
        <StepperStep completed={false}>
          <StepLabel>Confirm</StepLabel>
          <StepContent>
            <StepDescription>
              Please approve and confirm claim transaction.
            </StepDescription>

            <StatusBox>
              <ProcessingButton text="Processing..." disabled />
            </StatusBox>
          </StepContent>
        </StepperStep>

        {!canceled && !error && (
          <StepperStep completed={false}>
            <StepLabel>Success</StepLabel>
            <StepContent>
              <StepDescription>
                The transaction has been confirmed successfully!
              </StepDescription>

              <StatusBox>
                <CloseButton text="Close" onClick={onClose} />
              </StatusBox>
            </StepContent>
          </StepperStep>
        )}

        {canceled && (
          <StepperStep completed={false}>
            <StepLabel>Canceled</StepLabel>
            <StepContent>
              <StepDescription>
                The transaction has been canceled.
              </StepDescription>

              <StatusBox>
                <CloseButton text="Close" onClick={onClose} />
              </StatusBox>
            </StepContent>
          </StepperStep>
        )}

        {error && (
          <StepperStep completed={false}>
            <StepLabel>Rejected</StepLabel>
            <StepContent>
              <StepDescription>
                The transaction has been rejected, something went wrong.
              </StepDescription>

              <StatusBox>
                <CloseButton text="Close" onClick={onClose} />
              </StatusBox>
            </StepContent>
          </StepperStep>
        )}
      </Stepper>
    </Wrapper>
  );
};
