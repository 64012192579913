import React from 'react';
import { AbstractConnector } from '@web3-react/abstract-connector';

import { getWalletIcon } from 'helpers/getWalletIcon';
import { Wrapper, IconContent } from './Account.styles';

type Props = {
  address: string;
  connector: AbstractConnector | undefined;
  onClick: () => void;
};

export const Account = ({ address, connector, onClick }: Props) => {
  //
  const addressShortend = address.slice(0, 6) + '...' + address.slice(-4);

  return (
    <Wrapper>
      <span>{addressShortend}</span>
      <IconContent onClick={onClick}>
        <img src={getWalletIcon(connector)} alt="Wallet icon" />
      </IconContent>
    </Wrapper>
  );
};
