import { BigNumber, BigNumberish } from 'ethers';

//
export const ApprovalState = {
  APPROVED: 'APPROVED',
  TO_BE_RESET: 'TO_BE_RESET',
  TO_BE_APPROVED: 'TO_BE_APPROVED',
};

//
export const checkDepositApproval = (
  amount: BigNumberish,
  allowance: string,
) => {
  //
  if (BigNumber.from(amount).lte(BigNumber.from(allowance))) {
    return ApprovalState.APPROVED;
  } else if (
    BigNumber.from(allowance).gt(BigNumber.from('0')) &&
    BigNumber.from(allowance).lt(BigNumber.from(amount))
  ) {
    return ApprovalState.TO_BE_RESET;
  } else {
    return ApprovalState.TO_BE_APPROVED;
  }
};
