import { useCallback, useContext, useState } from 'react';
import { ethers, Contract } from 'ethers';

import { useProvider, useWeb3 } from 'hooks';
import { getMaxInteger } from 'helpers/format';
import { SnackbarContext } from 'components/Snackbar';
import { StakingContract, ColonyERC20Token } from 'ethereum/contracts';
import { ApprovalState, checkDepositApproval } from 'helpers/approval';

export const STAKE_STATUS = {
  INIT: { step: 0, status: 'init' },
  RESET_NEEDED: {
    step: 0,
    status: 'reset_needed',
  },
  APPROVE_NEEDED: {
    step: 0,
    status: 'approve_needed',
  },
  CONFIRMATION_NEEDED: {
    step: 1,
    status: 'confirmation_needed',
  },
  SUCCESS: {
    step: 2,
    status: 'success',
  },
  CANCELED: {
    step: 2,
    status: 'canceled',
  },
  ERROR: { step: 2, status: 'error', label: 'Error', description: 'Error!' },
};

export function useStake() {
  //
  const { account } = useWeb3();
  const provider = useProvider();
  const { openSnackbar } = useContext(SnackbarContext);

  const [error, setError] = useState<any>();
  const [status, setStatus] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [canceled, setCanceled] = useState(false);

  const stake = useCallback(
    async (amount) => {
      try {
        if (!account) {
          throw new Error('No account connected');
        }

        setLoading(true);
        setCanceled(false);
        setError(undefined);
        setStatus(STAKE_STATUS.INIT);

        const staking = new Contract(
          StakingContract.address,
          StakingContract.abi,
          provider.getSigner(),
        );

        const token = new Contract(
          ColonyERC20Token.address,
          ColonyERC20Token.abi,
          provider.getSigner(),
        );

        const allowance = await token.allowance(account, staking.address);

        const approvalStatus = checkDepositApproval(
          ethers.utils.parseEther(amount),
          allowance,
        );

        if (approvalStatus === ApprovalState.TO_BE_RESET) {
          setStatus(STAKE_STATUS.RESET_NEEDED);
          const approveResetTx = await token.approve(
            staking.address,
            ethers.utils.parseEther('0'),
          );
          await approveResetTx.wait();

          setStatus(STAKE_STATUS.APPROVE_NEEDED);
          const approveMaximumTx = await token.approve(
            staking.address,
            getMaxInteger(),
          );
          await approveMaximumTx.wait();
        } else if (approvalStatus === ApprovalState.TO_BE_APPROVED) {
          setStatus(STAKE_STATUS.APPROVE_NEEDED);
          const approveMaximumTx = await token.approve(
            staking.address,
            getMaxInteger(),
          );
          await approveMaximumTx.wait();
        }

        setStatus(STAKE_STATUS.CONFIRMATION_NEEDED);
        const stakeTx = await staking.stake(ethers.utils.parseEther(amount));
        await stakeTx.wait();

        openSnackbar(
          'success',
          'Transaction Confirmed',
          'You can close transaction process.',
        );
        setStatus(STAKE_STATUS.SUCCESS);
      } catch (error: any) {
        if (error.code === 4001) {
          openSnackbar(
            'warning',
            'Transaction Canceled',
            'You can close transaction process.',
          );
          setCanceled(error);
          setStatus(STAKE_STATUS.CANCELED);
        } else {
          openSnackbar(
            'error',
            'Transaction Error',
            'Something went wrong. You can close transaction process.',
          );
          setError(error);
          setStatus(STAKE_STATUS.ERROR);
        }
      } finally {
        setLoading(false);
      }
    },
    [account, provider, openSnackbar],
  );

  return {
    error,
    status,
    loading,
    canceled,
    stake,
  };
}
