import React from 'react';
import { FormControlProps } from '@mui/material/FormControl';

import { StyledFormControl as MuiFormControl } from './FormControl.styles';

//
type Props = FormControlProps;

const FormControl = ({ ...props }: Props) => {
  return (
    <>
      <MuiFormControl {...props} />
    </>
  );
};

FormControl.defaultProps = {
  variant: 'outlined',
};

export default FormControl;
