import { AbstractConnector } from '@web3-react/abstract-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

export function getWalletName(
  connector: AbstractConnector | undefined,
): string | null {
  if (connector instanceof InjectedConnector) {
    return 'MetaMask';
  }

  if (connector instanceof WalletConnectConnector) {
    return 'Wallet Connect';
  }

  return null;
}
