import React, { ReactNode } from 'react';

import InputLabel from './composed/Label';
import HelperText from './composed/HelperText';
import FormControl from './composed/FormControl';
import OutlinedInput from './composed/OutlinedInput';

type Props = {
  id: string;
  name?: string;
  value: string;
  error: boolean;
  disabled: boolean;
  placeholder: string;
  endAdornment: ReactNode;
  label: string | ReactNode;
  helperText: string | ReactNode;
  onBlur: () => void;
  onChange: () => void;
};

const Input = ({
  id,
  name,
  value,
  error,
  label,
  disabled,
  helperText,
  placeholder,
  endAdornment,
  onBlur,
  onChange,
  ...props
}: Props) => (
  <>
    <FormControl error={error}>
      {label && (
        <InputLabel htmlFor={id} error={error}>
          {label}
        </InputLabel>
      )}
      <OutlinedInput
        id={id}
        error={error}
        value={value}
        disabled={disabled}
        name={name ? name : id}
        placeholder={placeholder}
        endAdornment={endAdornment}
        onBlur={onBlur}
        onChange={onChange}
        {...props}
      />
      {helperText && <HelperText error={error}>{helperText}</HelperText>}
    </FormControl>
  </>
);

Input.defaultProps = {
  value: '',
  type: 'text',
  error: false,
  placeholder: '',
  variant: 'outlined',
};

export default Input;
