import React, { ReactElement, ReactNode } from 'react';
import { TooltipProps } from '@mui/material';

import { Content, StyledTooltip as MuiTooltip } from './Tooltip.styles';

//
type Props = TooltipProps & {
  title: string;
  children: string | ReactElement | ReactNode;
};

export const Tooltip = ({ title, children, ...props }: Props) => {
  return (
    <MuiTooltip title={<Content>{title}</Content>} {...props}>
      {children}
    </MuiTooltip>
  );
};
