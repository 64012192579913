import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  && {
    .MuiPaper-root {
      border-radius: 4px;
      background-color: #272727;
      border: solid 1px #3c3c3c;
    }
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  margin: 0 20px;
  padding: 20px 0;
  position: relative;
  border-bottom: 1px solid #333;

  span {
    color: #b092ed;
    font-size: 16px;
    font-weight: 500;
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    left: 0;
    bottom: -2px;
    width: 32px;
    height: 2px;
    background: url('/title-element.svg');
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 0 20px;
  }
`;

export const CloseIcon = styled(Close)`
  && {
    width: 16px;
    height: 16px;
    top: 16px;
    right: -4px;
    cursor: pointer;
    position: absolute;
  }
`;
