import { styled } from '@mui/material/styles';
import { styled as styledd } from '@mui/material/styles';

export const Wrapper = styledd('div')`
  display: flex;
  width: 210px;
  margin: 0;
  padding: 0;

  @media screen and (min-width: 360px) {
    width: 250px;
  }

  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.values.sm}px) {
    width: 290px;
  }
`;

export const Content = styled('div')`
  display: flex;
  width: 290px;
  flex-direction: column;
  margin: 30px 0 20px;
  padding: 0;
`;

export const Value = styled('div')`
  display: flex;
  margin: 0;

  span {
    color: #b092ed;
    font-size: 12px;
    font-weight: 500;
  }
`;

export const Header = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Balance = styled('div')`
  display: flex;
  margin: 20px 0 8px;

  span {
    color: #b092ed;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const Details = styled('table')`
  margin: 30px 0;
`;

export const DetailRow = styled('tr')`
  border-spacing: 2px;
`;

export const DetailLabel = styled('td')`
  padding: 8px 14px;
  color: #bdbdbd;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  border-left: 1px solid #333;
  border-right: 1px solid #333;

  &:after {
    display: block;
    content: '';
    left: -1px;
    right: 2px;
    bottom: -3px;
    height: 1px;
    background: #333;
    position: absolute;
  }
`;

export const DetailLabelLast = styled(DetailLabel)`
  &:after {
    display: none;
  }
`;

export const DetailValue = styled('td')`
  margin: 0;
  padding: 8px 14px;
  color: #40d7f3;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  text-align: center;

  &:after {
    display: block;
    content: '';
    left: -2px;
    right: 0px;
    bottom: -3px;
    height: 1px;
    background: #333;
    position: absolute;
  }
`;

export const DetailValueLast = styled(DetailValue)`
  &:after {
    display: none;
  }
`;

export const Actions = styledd('div')`
  display: flex;
  width: 100%;

  > .MuiButton-root {
    margin: 0 auto;
    width: 100%;
  }

  @media screen and (min-width: ${({ theme }) =>
    theme.breakpoints.values.md}px) {
    > .MuiButton-root {
      margin: 0 auto;
      width: 60%;
    }
  }
`;

export const IconContent = styled('div')`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 38px;
  border: 1px solid #3c3c3c;
  background-color: #333333;

  > img {
    width: 50px;
    height: auto;
  }
`;
