import { styled } from '@mui/material/styles';
import { Box, Paper, Typography } from '@mui/material';

export const Wrapper = styled('div')`
  height: 100%;
`;

export const StyledPaper = styled(Paper)`
  && {
    height: 100%;
    margin: 0;
    padding: 12px 16px;
    background: #272727;

    background-color: #272727;
    border: solid 1px #2c2c2c;
    box-shadow: 0 8px 4px -3px #1e1e1e, 0 4px 0 -3px rgba(18, 18, 18, 0.35),
      inset 0 -1px 0 0 rgba(18, 18, 18, 0.2);
  }
`;

export const Title = styled('div')`
  display: block;
  margin: 0;
  padding: 0;
`;

export const StyledTypography = styled(Typography)``;

export const StyledBox = styled(Box)`
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  margin: 8px 0 16px;
  padding: 0;

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 1px;
    height: auto;
    left: -16px;
    top: 10px;
    bottom: 4px;
    background: #353535;
  }
`;

export const BoxHeader = styled('div')`
  display: flex;
  width: 100%;
  position: relative;

  > h4 {
    display: block;
    margin: 0;
    padding: 0 0 4px;
    color: #b092ed;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
  }

  > span {
    display: block;
    margin: 4px -16px 8px 8px;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid #353535;
  }

  &:after {
    display: block;
    position: absolute;
    content: '';
    width: 3px;
    height: 15px;
    left: -16px;
    bottom: 2px;
    background: url('/title-element-right.svg');
  }
`;

export const BoxContent = styled('div')`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid #353535;

  &:after {
    display: block;
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 32px;
    height: 2px;
    background: url('/title-element.svg');
  }

  > p {
    display: block;
    margin: 0 16px 0 0;
    padding: 4px 0 10px;
    position: relative;
    color: #bdbdbd;
  }
`;

export const BoxRow = styled('div')`
  display: flex;
  margin: 0 0 2px;
`;

export const BoxLabel = styled('div')`
  display: block;
  margin: 0;
  color: #bdbdbd;
  font-size: 12px;
  font-weight: 500;
`;

export const BoxValue = styled('div')`
  display: block;
  margin: 0;
  padding: 0 0 0 2px;
  color: #40d7f3;
  font-size: 12px;
  font-weight: 500;
`;

export const BoxTitle = styled('h5')`
  display: block;
  margin: 2px 0 8px;
  padding: 0;
  color: #a195ee;
  font-size: 12px;
  font-weight: 500;
`;

export const ListContent = styled('div')`
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 0 10px;
  position: relative;
  border-bottom: 1px solid #353535;

  &:after {
    display: block;
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    width: 32px;
    height: 2px;
    background: url('/title-element.svg');
  }
`;

export const List = styled('ul')`
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 0;
  max-height: 160px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #bdbdbd #9e9e9e;

  
  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 20px;
    box-shadow: inset 0 0 5px #bdbdbd;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #bdbdbd;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #bdbdbd;
`;

export const ListItem = styled('li')`
  display: flex;
  width: 100%;
  margin: 0 0 16px;
  padding: 0;

  > img {
    display: block;
    width: auto;
    height: 60px;
    margin: 0 10px 0 0;
    padding: 0;
  }

  > div {
    display: block;
    width: 100%;
  }
`;
