import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { ArrowDropDown } from '@mui/icons-material';
import { Collapse, List, ListItem } from '@mui/material';

import {
  SvgIndexIco,
  SvgStakeIco,
  SvgStudioIco,
  SvgDashboardIco,
  SvgPortfolioIco,
  SvgEearlyStageIco,
  SvgStakingProgramIco,
  SvgLiquidityProgramIco,
} from 'components/Icons';

type ActiveIconProps = {
  active?: boolean | number;
};

type ListItemProps = {
  active?: boolean | number;
};

//
export const Wrapper = styled('div')`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 20;
  min-width: 250px;
  flex-direction: column;
`;

export const StyledList = styled(List)`
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
`;

export const StyledListItem = styled(ListItem)<ListItemProps>`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 4px 0;
  cursor: pointer;

  &.Mui-disabled {
    opacity: 1;
    pointer-events: none;
  }

  {*
    ${({ active }) =>
      active &&
      `
        border-radius: 3px;
        border: 1px solid #00b1d1;
        background-color: rgba(64, 215, 243, 0.1);
      `};
  *}
`;

export const ItemListContent = styled('div')`
  display: flex;
  width: 100%;
  margin: 0;
  padding: 10px 12px;
  align-items: center;

  > div {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    span {
      display: inline-block;
      margin: 0 0 0 12px;
      padding: 0;
      color: #bababa;
      font-size: 1rem;
    }

    small {
      color: #8f8f8f;
      font-weight: bold;
      font-size: 0.75rem;
    }
  }
`;

export const StyledCollapse = styled(Collapse)`
  display: block;
  margin: 0;
  padding: 0 0 0 48px;
`;

export const SubmenuList = styled(List)`
  display: block;
  position: relative;
  margin: 16px 0;
  padding: 0;

  &:before {
    display: block;
    width: 1px;
    content: '';
    left: 0;
    top: 0px;
    bottom: 0px;
    position: absolute;
    background-color: #383838;
  }
`;

export const SubmenuListItem = styled(ListItem)`
  display: block;
  width: 100%;
  margin: 0;
  padding: 10px 12px;

  span {
    display: inline-block;
    margin: 0 0 0 12px;
    padding: 0;
    color: #bababa;
    font-size: 1rem;
  }

  &.Mui-disabled {
    opacity: 1;
    pointer-events: none;
  }
`;

export const StyledLink = styled(NavLink)`
  && {
    display: block;
    width: 100%;
    text-decoration: none;

    &.active {
      border-radius: 3px;
      border: 1px solid #00b1d1;
      background-color: rgba(64, 215, 243, 0.1);

      > div {
        span {
          color: #40d7f3;
          font-weight: 500;
        }
      }
    }
  }
`;

export const StyledSubLink = styled(NavLink)`
  && {
    display: block;
    width: 100%;
    text-decoration: none;

    &.active {
      > li {
        span {
          color: #40d7f3;
        }
      }
    }
  }
`;

export const StyledArrowDropdownIcon = styled(ArrowDropDown)<ActiveIconProps>`
  && {
    ${({ active }) =>
      active &&
      `
        transform: rotate(180deg);
      `};
  }
`;

export const IndexIcon = styled(SvgIndexIco)<ActiveIconProps>``;

export const StakeMenuIcon = styled(SvgStakeIco)<ActiveIconProps>``;

export const StudioMenuIcon = styled(SvgStudioIco)<ActiveIconProps>``;

export const EearlyStageIcon = styled(SvgEearlyStageIco)<ActiveIconProps>``;

export const PortfolioMenuIcon = styled(SvgPortfolioIco)<ActiveIconProps>``;

export const DashboardMenuIcon = styled(SvgDashboardIco)<ActiveIconProps>``;

export const StakingProgramIco = styled(
  SvgStakingProgramIco,
)<ActiveIconProps>``;

export const LiquidityProgramIcon = styled(
  SvgLiquidityProgramIco,
)<ActiveIconProps>``;
