import React from 'react';

import { Loader } from 'components/Loader';
import { StyledButton as Button } from './Processing.styles';

//
type Props = {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
};

export const ProcessingButton = ({ text, disabled, onClick }: Props) => {
  return (
    <Button
      variant="contained"
      disabled={disabled}
      endIcon={<Loader size={18} />}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
