import { AbstractConnector } from '@web3-react/abstract-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

export function getWalletIcon(connector: AbstractConnector | undefined) {
  if (connector instanceof InjectedConnector) {
    return '/metamask-logo.svg';
  }

  if (connector instanceof WalletConnectConnector) {
    return '/walletconnect-logo.svg';
  }

  return '';
}
