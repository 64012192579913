import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { Alert, Snackbar, IconButton } from '@mui/material';

//
export const StyledSnackbar = styled(Snackbar)``;

export const StyledAlert = styled(Alert)`
  && {
    display: flex;
    margin: 0;
    padding: 0;
    min-width: 300px;
    position: relative;

    @media screen and (min-width: 360px) {
      min-width: 345px;
      max-width: 360px;
    }

    @media screen and (min-width: ${({ theme }) =>
        theme.breakpoints.values.sm}px) {
      min-width: 345px;
      max-width: 675px;
    }

    &.MuiAlert-standardSuccess {
      background-color: #1b3330;
      border: 1px solid #00a28e;
      box-shadow: 0 9px 30px 0 #121212, 0 5px 0 -2px #2c2c2c, 0 1px 0 0 #3c3c3c,
        inset 0 1px 0 2px #1e1e1e, inset -1px 0 0 1px #00a28e,
        inset 1px 0 0 1px #00a28e, inset 0 -1px 0 1px #00a28e;

      .MuiAlert-icon {
        display: flex;
        margin: 0;
        padding: 0;
        align-items: flex-start;

        > .MuiSvgIcon-root {
          display: block;
          width: 18px;
          height: 18px;
          margin: 0;
          padding: 7px;
          color: #fff;
          border-radius: 0 0 4px 0;
          box-sizing: content-box;
          background-color: #00a28e;
        }
      }

      .MuiAlert-message {
        display: flex;
        width: 100%;
        margin: 0 12px;
        padding: 10px 0 12px;
        flex-direction: column;

        > h3 {
          display: block;
          margin: 0 0 4px;
          padding: 0;
          color: #20ae9c;
          font-size: 12px;
          font-weight: 600;
        }

        > span {
          display: block;
          margin: 0 0 2px;
          padding: 0;
          color: #bdbdbd;
          font-size: 12px;
          align-items: center;
          letter-spacing: 0.2px;
        }
      }
    }

    &.MuiAlert-standardWarning {
      border: solid 1px #ed6f01;
      background-color: #3f2b1b;
      box-shadow: 0 9px 30px 0 #121212, 0 5px 0 -2px #2c2c2c, 0 1px 0 0 #3c3c3c,
        inset 0 1px 0 2px #1e1e1e, inset -1px 0 0 1px #ed6f01,
        inset 1px 0 0 1px #ed6f01, inset 0 -1px 0 1px #ed6f01;

      .MuiAlert-icon {
        display: flex;
        margin: 0;
        padding: 0;
        align-items: flex-start;

        > .MuiSvgIcon-root {
          display: block;
          width: 18px;
          height: 18px;
          margin: 0;
          padding: 7px;
          color: #fff;
          border-radius: 0 0 4px 0;
          box-sizing: content-box;
          background-color: #ed6f01;
        }
      }

      .MuiAlert-message {
        display: flex;
        width: 100%;
        margin: 0 12px;
        padding: 10px 0 12px;
        flex-direction: column;

        > h3 {
          display: block;
          margin: 0 0 4px;
          padding: 0;
          color: #f08121;
          font-size: 12px;
          font-weight: 600;
        }

        > span {
          display: block;
          margin: 0 0 2px;
          padding: 0;
          color: #bdbdbd;
          font-size: 12px;
          align-items: center;
          letter-spacing: 0.2px;
        }
      }
    }

    &.MuiAlert-standardInfo {
      background-color: #1b3330;
      border: 1px solid #00a28e;
      box-shadow: 0 9px 30px 0 #121212, 0 5px 0 -2px #2c2c2c, 0 1px 0 0 #3c3c3c,
        inset 0 1px 0 2px #1e1e1e, inset -1px 0 0 1px #00a28e,
        inset 1px 0 0 1px #00a28e, inset 0 -1px 0 1px #00a28e;

      .MuiAlert-icon {
        display: flex;
        margin: 0;
        padding: 0;
        align-items: flex-start;

        > .MuiSvgIcon-root {
          display: block;
          width: 18px;
          height: 18px;
          margin: 0;
          padding: 7px;
          color: #fff;
          border-radius: 0 0 4px 0;
          box-sizing: content-box;
          background-color: #00a28e;
        }
      }

      .MuiAlert-message {
        display: flex;
        width: 100%;
        margin: 0 12px;
        padding: 10px 0 12px;
        flex-direction: column;

        > h3 {
          display: block;
          margin: 0 0 4px;
          padding: 0;
          color: #20ae9c;
          font-size: 12px;
          font-weight: 600;
        }

        > span {
          display: block;
          margin: 0 0 2px;
          padding: 0;
          color: #bdbdbd;
          font-size: 12px;
          align-items: center;
          letter-spacing: 0.2px;
        }
      }
    }

    &.MuiAlert-standardError {
      background-color: #3a2a2d;
      border: 1px solid #cf6679;
      box-shadow: 0 9px 30px 0 #121212, 0 5px 0 -2px #2c2c2c, 0 1px 0 0 #3c3c3c,
        inset 0 1px 0 2px #1e1e1e, inset -1px 0 0 1px #cf6679,
        inset 1px 0 0 1px #cf6679, inset 0 -1px 0 1px #cf6679;

      .MuiAlert-icon {
        display: flex;
        margin: 0;
        padding: 0;
        align-items: flex-start;

        > .MuiSvgIcon-root {
          display: block;
          width: 18px;
          height: 18px;
          margin: 0;
          padding: 7px;
          color: #fff;
          box-sizing: content-box;
          border-radius: 0 0 4px 0;
          background-color: #cf6679;
        }
      }

      .MuiAlert-message {
        display: flex;
        width: 100%;
        margin: 0 12px;
        padding: 10px 0 12px;
        flex-direction: column;

        > h3 {
          display: block;
          margin: 0 0 4px;
          padding: 0;
          color: #d5798a;
          font-size: 12px;
          font-weight: 600;
        }

        > span {
          display: block;
          margin: 0 0 2px;
          padding: 0;
          color: #bdbdbd;
          font-size: 12px;
          align-items: center;
          letter-spacing: 0.2px;
        }
      }
    }
  }
`;

export const CloseIcon = styled(Close)`
  && {
    width: 16px;
    height: 16px;
    color: #fff;
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    display: inline-flex;
    padding: 12px;
    width: 24px;
    height: 24px;
    top: 2px;
    right: 2px;
    position: absolute;
  }
`;
